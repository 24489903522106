

import { useState,useContext } from 'react'

import CourseContext from './courseContext'

export const useCourseContext = () => useContext(CourseContext);
export default function CourseContextProvider({children}) {
  const [state,setstate]=useState([])
  
  return (
    <CourseContext.Provider value={{state,setstate}}>
       {children}
    </CourseContext.Provider>
     
  )
}
