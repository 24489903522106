
import { Link } from "react-router-dom"
import "./footer.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope,faPhone,faArrowRight,faLocationDot,faGlobe} from '@fortawesome/free-solid-svg-icons'
import { faInstagram,faLinkedin} from '@fortawesome/free-brands-svg-icons'

const Footer=()=>{
return(
    <div>
    <div class="footer" >
        <div  class="footer-1">
        <div >
            <h4 class="foothead">Company</h4>
            <Link to="/"class="footmenue"><p><FontAwesomeIcon icon={faArrowRight} />  Home</p></Link>
            <Link to="/courses"class="footmenue"><p><FontAwesomeIcon icon={faArrowRight} />  IICET Courses</p></Link>
            <Link to="/postgraduate"class="footmenue"><p><FontAwesomeIcon icon={faArrowRight} />  IICET Post Graduate Diploma</p></Link>
            <h4 class="foothead">Send your feedback</h4>
            <p><FontAwesomeIcon icon={faEnvelope} /> enquiry@iicets.com</p>
        </div>
        <div>
        <h4 class="foothead">Quick Links</h4>
        <Link to="/admission"class="footmenue"><p><FontAwesomeIcon icon={faArrowRight} />  Admission</p></Link>
        <Link to="/placements"class="footmenue"><p><FontAwesomeIcon icon={faArrowRight} />  Placements</p></Link>
        <Link to="/admission"class="footmenue"><p><FontAwesomeIcon icon={faArrowRight} />  Contact Us</p></Link>
            <h4 class="foothead">Follow Us</h4>
       
            <p ><a target="_blank" href="https://www.instagram.com/iicetsatara.in?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" class="footmenue"><FontAwesomeIcon icon={faInstagram} />  iicetsatara.in </a> <br />
            <a class="footmenue" target="_blank" href="https://www.iicets.com/"><FontAwesomeIcon icon={faGlobe} />  www.iicets.com</a><br /> 
            <a class="footmenue" target="_blank"  href="https://www.linkedin.com/company/iicet/about/"><FontAwesomeIcon icon={faLinkedin} />  IICET</a></p>
           
        </div>
        <div>
        <h4 class="foothead">Get In Touch</h4>
            <p class="footmenue"><FontAwesomeIcon icon={faLocationDot} />   M2Q3+5FG, Khalcha Rasta,<br/> Raviwar Peth, Satara, <br/>Maharashtra 415001, India</p>
            <p class="footmenue"><FontAwesomeIcon icon={faEnvelope} /> enquiry@iicets.com</p>
            <p class="footmenue" ><FontAwesomeIcon icon={faPhone} /> 8485819119 <br /><FontAwesomeIcon icon={faPhone} /> 7517819119<br/><FontAwesomeIcon icon={faPhone} /> 7757898255</p>
            </div>
        </div>
        
    </div>
    <div class="footer-2"> </div>
    </div>
)}
export {Footer}