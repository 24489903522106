import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
const MyNavbar=()=>{
    return <>
<Navbar expand="lg" style={{backgroundColor:'#E8F6F3'}} >
      <Container style={{marginBottom:'-8px',marginTop:'-5px'}}>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className= "justify-content-center" id="basic-navbar-nav">
        <Nav  variant="tabs"   defaultActiveKey="/home">
      <Nav.Item>
        <Nav.Link href="/home" ><Link className="btn btn-success" style={{marginBottom: '-8px'}}   to ="/" >Home</Link></Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-1"><Link className="btn btn-success" style={{marginBottom: '-8px'}}   to ="/industry" >Industry</Link></Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-2"><Link className="btn btn-success" style={{marginBottom: '-8px'}}   to ="/technology" >Services</Link></Nav.Link>
      </Nav.Item>
        <Nav.Item>
        <Nav.Link eventKey="link-3"><Link className="btn btn-success" style={{marginBottom: '-8px'}}   to ="/admission" >Admission</Link></Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link  eventKey="link-4"><Link className="btn btn-success"  style={{marginBottom: '-8px'}}  to ="/courses" >Diploma Courses</Link></Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-5"><Link className="btn btn-success" style={{marginBottom: '-8px'}}   to ="/postgraduate" >Post Graduate Diploma</Link></Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-6"><Link className="btn btn-success" style={{marginBottom: '-8px'}} to ="/placements" >Placements</Link></Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="link-7"><Link className="btn btn-success" style={{marginBottom: '-8px'}} to ="/contact" >Contact</Link></Nav.Link>
      </Nav.Item>
      
    </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
}

export {MyNavbar}