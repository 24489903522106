import {Route,Routes} from "react-router-dom"
import {Home} from "../Pages/home";
import {Courses} from "../Pages/courses";
import { Admissions } from "../Pages/admissions";
import { Placements } from "../Pages/placements";
import { Postgraduate } from "../Pages/postgraduate";
import { CourseDetails } from "../component/courseDetails";
import { Contact } from "../Pages/Contact";
import {Technology} from "../Pages/technology"
import {Industry} from "../Pages/industry"

const AllRoutes = () => {
  return <>
  <Routes>
    <Route path="/" element ={<Home/>}/>
    <Route path="/technology" element ={<Technology/>}/>
    <Route path="/industry" element ={<Industry/>}/>

    <Route path="/courses" element ={<Courses/>}/>
    <Route path="/courseDetails" element ={<CourseDetails />}/>
    <Route path="/placements" element ={<Placements/>}/>
    <Route path="/postgraduate" element ={<Postgraduate/>}/>
    <Route path="/admission" element ={<Admissions/>}/>
    <Route path="/contact" element ={<Contact/>}/>

    

  </Routes>
  </>;
};

export {AllRoutes};
