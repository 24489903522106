import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import bgimg from '../img/bgadblr.jpg'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import  "./admission.css"

function Admissions() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_45b7n2a', 'template_gd4y9b1', form.current, 'XWp4u_Db2AjAGPr_p')
      .then((result) => {
          console.log(result.text);
          alert("message sent");
      }, (error) => {
          console.log(error.text);
          alert("error");
      });

     
  };



    return (
      <div class="bgimg" style={{backgroundImage: `url(${bgimg})`, padding:'20px',marginTop:'25px'}}>

      <div class="formCard">
   <Form ref={form} onSubmit={sendEmail}> 
   <Row className="mb-3" class="forMobrow">
        <Col>
        <Form.Label>First name</Form.Label>
          <Form.Control placeholder="First name" name="first_name"/>
        </Col>
        <Col>
        <Form.Label>Last name</Form.Label>
          <Form.Control placeholder="Last name" name="last_name" />
        </Col>
      </Row>
      <Row className="mb-3"  class="forMobrow">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Enter email" name="user_email"/>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridPassword">
          <Form.Label>Mobile Number</Form.Label>
          <Form.Control type="number" placeholder="Mobile Number" name="user_number"/>
        </Form.Group>
      </Row>

      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label>Address</Form.Label>
        <Form.Control placeholder="1234 Main St" name="user_address"/>
      </Form.Group>

      

      <Row className="mb-3"  class="forMobrow">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>City</Form.Label>
          <Form.Control name="user_city"/>
        </Form.Group>

        

        <Form.Group as={Col} controlId="formGridZip">
          <Form.Label>Zip</Form.Label>
          <Form.Control name="user_zip"/>
        </Form.Group>
      </Row>

      <Row  class="forMobrow">
      <Form.Group as={Col} controlId="formGridpgd">
          <Form.Label>Interested In PG Diploma Course</Form.Label>
          <Form.Select defaultValue="Choose..." name="user_pgdeploma">
          <option>Choose . . .</option>
            <option>IICET Software Development</option>
            <option>IICET Computer Aplications</option>
            <option>IICET Full Stack Development</option>
            <option>IICET Datascience & Artificial Intelligence</option>
            <option>IICET Automation and Manual Testing</option>
            <option>IICET DevOps</option>
            <option>IICET Cloud Computing</option>
            <option>IICET Data Analytics</option>
          </Form.Select>
        </Form.Group>
      <Form.Group as={Col} controlId="formGridcourse">
          <Form.Label>Interested In Course</Form.Label>
          <Form.Select defaultValue="Choose..." name="user_course">
          <option>Choose . . .</option>
            <option>IICET C Programming</option>
            <option>IICET C++ Programming</option>
            <option>IICET Java Programming</option>
            <option>IICET Python Programming</option>
            <option>IICET #/.Net Programming</option>
            <option>IICET SQL Programming</option>
            <option>IICET Front End Development</option>
            <option>IICET Tally with GST</option>
          </Form.Select>
        </Form.Group>
       
      </Row>

     
      {/* <Button type="submit" value="Send" /> */}
      <Button variant="primary" className='mt-4' value="Send" type="submit">
        Submit
      </Button>
    </Form>
  
      </div>
      </div>
  
  
    );
  }
  
  export { Admissions };