import React from 'react'

import { useState,useContext } from 'react'
import c from "../img/c.png"
import cpp from "../img/c++.png"
import java from "../img/java.png"
import python from "../img/python.png"
import sql from "../img/sql.png"
import php from "../img/php.png"
import net from "../img/net.png"
import PgdCourseContext from './pgdCourseContext'

const store2=[
    {img:c,name:"C2"},
    {img:cpp,name:"C++2"},
    {img:java,name:"JAVA2"},
    {img:python,name:"PYTHON2"},
    {img:sql,name:"SQL2"},
    {img:php,name:"PHP2"},
    {img:net,name:".NET2"}
 
  ];
export default function PgdCourseContextProvider({children}) {
  const [state1,setstate1]=useState(store2)
  
  return (
    <PgdCourseContext.Provider value={{state1,setstate1}}>
    {children}
    </PgdCourseContext.Provider>
     
  )
}
