import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './App.css';
import { Header } from './component/header';
import { MyNavbar } from './component/navbar';
import { Footer } from './component/footer';
import {AllRoutes} from './Routes/AllRoutes';

function App() {
  return (
    <div className="App">
    <Header/>
     <MyNavbar/>
     <AllRoutes/>
     <Footer/>
    </div>
  );
}

export default App;
