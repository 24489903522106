
import { Card } from "react-bootstrap"
let industrystore=[
  {name:"SMART CITIES"},
  {name:"WAREHOUSING"},
  {name:"MANUFACTURING"},
  {name:"ENERGY"},

  {name:"ENTERTAINMENT"},
  {name:"SPACE/EARTH INSIGHTS"},
  {name:"HEALTHCARE"},
  {name:"FINANCE"},

  {name:"AGRICULTURE"},
  {name:"EDUCATION"},
  {name:"TRANSPORTATION"},
  {name:"ENVIRONMENT MONITORING"} 
 ]

function Industry() {



    return (
      <div id="courses">
      <div className="m-auto " style={{width:'80%'}}>
<div><h2 className="mt-5">INDUSTRY</h2></div></div>
<div className="mt-2"><h5 class="intro">WE WORK IN THESE INDUSTRIES</h5></div>
 <div className="d-flex justify-content-center flex-wrap mb-5" style={{width:'80%', margin:'auto'}}>
  {industrystore.map((ele)=>(
    <Card className="crds3" style={{ width: '40%', margin :'15px',borderRadius:'10px',boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px'}}>

    <Card.Body>
      <Card.Title style={{fontWeight:700}}>{ele.name}</Card.Title>
      <Card.Text>
    
       
      </Card.Text>
     
    </Card.Body>
  </Card>
  ))}

</div>

    </div>
  
    );
  }
  
  export { Industry };