import logo from '../img/logo.jpeg';
import msme from'../img/msme.jpg';
import isologo from'../img/isologo.png';
import brandlogo from'../img/brandlogo.png'
import Skill_India from '../img/Skill_India.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope} from '@fortawesome/free-solid-svg-icons'

import './header.css'
const Header=()=>{
       
    return<>
    <div className="d-flex justify-content-center  align-items-center flex-wrap">
        <div class="heads2">
        <img src={brandlogo} class="brandlogo " alt="logo" />
        </div>
        <div className="card border border-0 m-1"><h3 className="m-auto">IICET SERVICES PRIVATE LIMITED</h3><h2 class="text-danger text-center">INDIAN INSTITUTE OF COMPUTER EDUCATION AND TECHNOLOGY</h2>
        <h4 className="m-auto">इंडियन इंस्टिट्यूट ऑफ कॉम्पूटर एज्युकेशन अ‍ॅण्ड टेक्नॉलॉजी</h4>
        </div>
        
        <div><div className="d-flex">
        <img src={msme} class="logo " alt="msmelogo" />
        <img src={Skill_India}  class="logo border-end border-start" alt="skilllogo" />
        <img src={isologo} class="logo" alt="isologo" />
       
       
        
        </div>
        <div > <p class="smalltext"> <FontAwesomeIcon icon={faEnvelope} />  enquiry@iicets.com</p></div>
        </div>
    </div>
    </>
}

export {Header}