import Carousel from 'react-bootstrap/Carousel';
import img from '../img/gtr.jpeg';
import './home.css'
import { Card,Button, Row, Col, Image } from 'react-bootstrap';
import logo from '../img/gtr1.jpeg'
import coursel1 from '../img/Carousel1.jpg'
import coursel2 from '../img/Carousel.jpg'
import c from "../img/c.png"
import cpp from "../img/c++.png"
import java from "../img/java.png"
import python from "../img/python.png"

import sql from "../img/sql.png"

import net from "../img/net.png"
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import desktop1 from "../img/desktop1.jpeg"
import desktop2 from "../img/desktop2.jpeg"

let store=[
  {img:c,name:"C Program"},
  {img:cpp,name:"C++ Program"},
  {img:sql,name:"SQL Program"},
  {img:net,name:".NET Program"},
   {img:python,name:"Python Program"},
 

]



function Home() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleWindowSizeChange() {
      setIsMobile(window.innerWidth < 768); // Assuming mobile breakpoint is 768px
    }

    window.addEventListener('resize', handleWindowSizeChange);

    handleWindowSizeChange();

    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  function isDesktopMode() {
    return !isMobile && window.innerWidth >= 1024; 
  }

  // Function to handle the popup
  function handlePopup() {
    if (isMobile && !isDesktopMode()) {
      setShowModal(true);
     
    }
  }


  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    handlePopup();
     // Set showModal to true to show the modal on component mount
  }, [isMobile]);

  const handleClose = () => setShowModal(false);
  return (
    <div id="home">
<div className="AppModal">
<Modal
 show={showModal} onHide={handleClose}
      
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      
      </Modal.Header>
      <Modal.Body>
        <h4> Kindly Use Desktop-site for better Experience </h4>
        <p>
        We're currently working on our website to make it even better. Please excuse the mess!
        </p>
        <Row >
          <Col class="desktopsiteimg"><Image   style={{width:'100%'}} src={desktop1}></Image></Col>
          <Col class="desktopsiteimg"><Image  style={{width:'100%'}}  src={desktop2}></Image></Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
    </div>

      <div className="mt-5">
        <Carousel>
          <Carousel.Item>
            <img src={img} class="carosoulImg" alt="" />
            <Carousel.Caption>
              <h3>WELCOME</h3>
              <p>To the world of computer education IICET Satara</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={coursel1} class="carosoulImg" alt="" />
            <Carousel.Caption>
              <h3>WELCOME</h3>
              <p>To the world of computer education IICET Satara</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img src={coursel2} class="carosoulImg" alt="" />
            <Carousel.Caption>
              <h3></h3>
              <p>
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>

      <div className="m-auto" style={{width:'60%'}}>
<div className="mt-5"><h5 class="intro">Introduction</h5></div>
<div><h2>Welcome to IICET Courses</h2></div>
<div><h5 class="para"  >Welcome to the vibrant world of Indian Institute of Computer Education and Technology (IICET)! At IICET, we pride ourselves on being at the forefront of technological innovation and excellence in education. Established with a vision to empower individuals with cutting-edge knowledge and skills in the realm of computer science and technology, we are committed to nurturing the next generation of industry leaders, innovators, and problem solvers.
<br/><br/>
With a rich legacy of academic excellence and a dynamic learning environment, IICET offers a comprehensive range of programs designed to meet the evolving needs of the industry and society. Our faculty members are seasoned experts and thought leaders who are dedicated to providing world-class education and mentorship to our students.
<br/><br/>
Whether you aspire to pursue a career in software development, cybersecurity, data science, artificial intelligence, or any other cutting-edge field, IICET is your gateway to success. Join us on a transformative journey where your passion for technology meets endless possibilities. Explore our programs, connect with our vibrant community, and embark on a path to a brighter future with IICET.
</h5></div>
      </div>

      
      <div className="m-auto" style={{width:'80%'}}>
<div  className="mt-5"><h5 class="intro">Different Categories
</h5></div>
<div><h2>IICET Tracks</h2></div>
<div className="d-flex justify-content-center flex-wrap">
  {store.map((ele)=>(
    <Card className="crds" style={{ width: '16rem',padding:'2em' ,margin :'20px',boxShadow:'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'}}>
    <Card.Img class="cardImg" variant="top" src={ele.img} />
    <Card.Body>
      <Card.Title>{ele.name}</Card.Title>
      <Card.Text>
        
      </Card.Text>
      {/* <Button variant="primary">Go somewhere</Button> */}
    </Card.Body>
  </Card>
  ))}

</div>
      </div>
    </div>

  );
}

export { Home };