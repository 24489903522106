import { Button,Card } from "react-bootstrap";

import "./postgraduate.css"

import java from "../img/java.png"

import { useNavigate } from "react-router-dom";

import { useCourseContext } from "../context/courseContextProvider";
import sofwaredevelopment from "../img/softwaredevelopment.jpg"
import computerapplication from "../img/computerapplication.jpg"
import fullstackdeveloper from "../img/fullstackdeveloper.jpg"
import ai from "../img/ai.jpg"
import testing from "../img/testing.jpg"
import devops from "../img/devops.png"
import cloud from "../img/cloudcomputing.jpg"
import dataanalytics from "../img/dataanalyatcs.png"
const store3=[
  {
// post graduate deploma courses
img:sofwaredevelopment,
  name:"Software Development Course",
  courseDescription:"This comprehensive course is designed to provide students with foundational to intermediate knowledge and skills in software development. Throughout the course, students will learn about software development methodologies, programming languages, data structures, databases, web development, and more. The curriculum includes lectures, labs, quizzes, a midterm exam, a final exam, and a capstone project that encompasses all the learned skills in a real-world software development scenario.", 
  courseObjective:["Gain proficiency in one or more programming languages.","Learn about data structures, algorithms, and their applications.","Develop backend applications and RESTful services.","Create dynamic, responsive frontend interfaces.","Work with relational and NoSQL databases.","Apply software testing techniques to ensure code quality.","Complete a comprehensive project that involves planning, designing, implementing, testing, and deploying a software application."],
  prerequisites:["Basic knowledge of computer operations and familiarity with the internet.","Prior experience in programming is beneficial but not required."], 
  courseOutline:[
    {module:"Introduction and Programming Fundamentals",
     moduleContents:["Overview of software development","Software development methodologies (Agile, Waterfall, Scrum)","Setting up the development environment","",""]},
    
    {module:"Programming Basics with Language X (e.g., Python, Java, C#)",
     moduleContents:["Variables, data types, and operators","Control structures and loops","Functions and error handling" ]},
    
     {module:"Introduction to Object-Oriented Programming (OOP)",
     moduleContents:["Classes and objects","Inheritance, encapsulation, and polymorphism", "Basic OOP principles and practices"]},

     {module:"Advanced Programming Concepts",
     moduleContents:[" File I/O","Exception handling advanced techniques"," Working with libraries and frameworks"  ]},

     {module:"Data Structures",
     moduleContents:["Arrays, linked lists, stacks, queues","Trees, heaps, graphs","Algorithms for sorting and searching" ]},

     {module:"Software Design and Development",
     moduleContents:["Software design patterns","Version control systems (Git)","Unit testing and Test-Driven Development (TDD)"  ]},

     {module:"Introduction to Frontend Development",
     moduleContents:["HTML and CSS","Responsive design with Bootstrap or Flexbox/Grid" ]},
     
     {module:"JavaScript and the DOM",
     moduleContents:["JavaScript basics and ES6 features","Manipulating the Document Object Model (DOM)","Events and event handling"   ]},
     
     {module:"Advanced Frontend Development",
     moduleContents:["AJAX, Fetch API, and making HTTP requests","Introduction to frontend frameworks (e.g., React, Angular, Vue.js)","Single Page Application (SPA) concepts" ]},
     
     {module:"Relational Databases and SQL",
     moduleContents:["Relational Databases and SQL","SQL queries: SELECT, INSERT, UPDATE, DELETE","Joins, subqueries, and transactions" ]},


     {module:"Backend Development Foundations",
     moduleContents:["Introduction to backend programming with Language X","RESTful web services concepts","MVC architecture" ]},
     
     {module:"Working with Frameworks",
     moduleContents:["Backend framework for Language X (e.g., Django for Python, Spring Boot for Java)","Routing, middleware, and controllers","CRUD operations and API development" ]},
     {module:"CRUD operations and API development",
     moduleContents:["User authentication methods","Session management","OAuth and JWT for secure APIs"]},
     {module:"NoSQL Databases",
     moduleContents:["NoSQL database concepts (e.g., MongoDB, Cassandra)","Document stores vs key-value stores vs column stores","Integrating NoSQL databases with backend applications" ]},
     {module:"Testing and Quality Assurance",
     moduleContents:["Functional testing, integration testing, and system testing","Mocking and test coverage","Continuous Integration (CI) and Continuous Delivery (CD) concepts" ]},
     {module:"Deployment and Monitoring",
     moduleContents:["Deployment strategies","Containerization with Docker","Monitoring and logging techniques" ]},
     {module:"DevOps Basics",
     moduleContents:["Introduction to DevOps and its tools","Infrastructure as Code (IaC)","Basic cloud deployment with AWS, Azure, or Google Cloud" ]},
     {module:"Midterm Project and Review",
     moduleContents:["Midterm project: Develop a small-scale application integrating frontend and backend.","Code reviews and feedback sessions." ]},
     {module:"Project Planning and Agile Methodologies",
     moduleContents:["Agile project management with tools like Jira or Trello","User stories, sprints, and retrospectives","Planning the capstone project" ]},
     {module:"Capstone Project Development",
     moduleContents:["Project design and architecture","Implementation of frontend and backend","Database integration and data management" ]},
     {module:"Testing and Deployment of Capstone Project",
     moduleContents:["Testing the project thoroughly","Deploying the project to a live environment","Preparing project documentation" ]},
     {module:"Final Examination and Project Presentation",
     moduleContents:["Final written examination covering all course content.","Presenting the capstone project to the class." ]},
     
     
    ],
  projectWork:[
    {title:"Project Proposal",desc:"Students will present a detailed proposal for a software application, defining the problem, the scope, and user requirements."},
    {title:"Development",desc:"Design and develop the application using the technologies learned throughout the course."},
    {title:"Testing and Documentation",desc:"Test the application and write comprehensive documentation."},
    {title:"Presentation",desc:"Present the final project to the class, highlighting key features, challenges, and learnings."},
    
    
],
projectIdeas:[""],
Evaluation:["Quizzes and Exercises: 20%","Midterm Project: 15%","Final Examination: 25%","Capstone Project: 30%","Participation and Attendance: 10%"],
TextbookAndResources:["Textbooks on programming languages and web development ","Textbooks on programming languages and web development ","Access to a cloud-based IDE or local development tools." ],
courseDuration:"The course lasts for approximately 5-6 months, with weekly sessions of 4 hours each, totaling around 104-124 hours of instruction.",
CoursePolicies:["Regular attendance is mandatory for successful completion.","Assignments and projects must be submitted by the specified deadlines.","Academic integrity is expected; plagiarism or cheating will result in a failing grade.","Students are encouraged to collaborate on learning but must submit their own work for assessments."],
summery:"By the end of the course, students will be equipped with a comprehensive understanding of the software development process and will be able to contribute effectively to real-world software projects."

},




{img:computerapplication,
  name:"Computer Applications ",
  courseDescription:"This course provides an overview of various computer applications that are essential for professional, educational, and personal productivity. Students will learn to use software applications effectively, including word processing, spreadsheets, databases, presentations, graphics editing, email, and internet research. The course will also introduce basic concepts in computer hardware, operating systems, and information security. The curriculum will consist of lectures, hands-on labs, quizzes, a midterm exam, a final exam, and a capstone project.", 
  courseObjective:["Develop proficiency in using popular computer applications.","Gain skills in creating professional presentations and graphics.","Learn to navigate operating systems and manage files and folders.","Understand the basics of computer hardware and software maintenance.","Practice safe computing and understand the basics of cybersecurity.","Apply the learned skills in a comprehensive project that solves a real-world problem" ],
  prerequisites:["Basic familiarity with using computers.","No prior experience with specific software applications is required."], 
  courseOutline:[
    {module:"Introduction to Computer Applications",
     moduleContents:["Overview of course structure","Basic computer hardware components and their functions","Introduction to operating systems (Windows, macOS, Linux)"]},
    
    {module:"Word Processing with Microsoft Word or Google Docs",
     moduleContents:["Creating, saving, and managing documents","Formatting text and paragraphs","Working with tables, images, and graphics","Advanced features: mail merge, footnotes, indexes" ]},
    
     {module:"Review and Test",
     moduleContents:["Review of word processing concepts and techniques","Practical test on word processing skills" ]},

     {module:"Introduction to Spreadsheets with Microsoft Excel or Google Sheets",
     moduleContents:["Basics of spreadsheet functions","Data entry and formatting cells","Basic formulas and functions" ]},

     {module:"Advanced Spreadsheet Functions",
     moduleContents:["Complex formulas and cell referencing","Data sorting, filtering, and validation","Using pivot tables for data analysis" ]},

     {module:"Introduction to Data Visualization",
     moduleContents:["Creating and customizing charts","Using conditional formatting for data insights","Introduction to dashboards "]},

     {module:"Review and Test",
     moduleContents:["Review of spreadsheet concepts and data visualization" ,"Practical test on spreadsheet skills"]},
     
     {module:"Presentation Software with Microsoft PowerPoint or Google Slides",
     moduleContents:["Creating and designing slide decks","Working with themes, templates, and master slides","Incorporating multimedia elements and animations" ]},
     
     {module:"Effective Presentation Techniques",
     moduleContents:["Principles of effective presentation design","Rehearsing and delivering presentations","Incorporating audience engagement techniques" ]},
     
     {module:"Database Management with Microsoft Access or a Similar Tool",
     moduleContents:["Understanding databases and relational data models","Designing and creating tables","Designing and creating tables" ]},


     {module:"Advanced Database Features",
     moduleContents:["Creating forms for data entry","Advanced querying techniques","Database security and permissions" ]},
     {module:"Introduction to Graphics Editing with Adobe Photoshop or GIMP",
     moduleContents:["Basics of image editing and manipulation","Working with layers and selections","Basic photo retouching techniques"  ]},
     {module:"Vector Graphics with Adobe Illustrator or Inkscape",
     moduleContents:["Understanding vector graphics","Creating illustrations, logos, and icons","Introduction to typography and text manipulation"  ]},
     {module:"Multimedia Applications",
     moduleContents:["Basics of video editing with applications like Adobe Premiere or iMovie","Introduction to audio editing with Audacity or similar tools","Creating multimedia presentations" ]},
     {module:"Review and Test",
     moduleContents:["Review of graphics, vector, and multimedia concepts","Practical test on multimedia skills" ]},
     {module:"Web Browsing and Internet Research Skills",
     moduleContents:["Effective use of search engines","Evaluating the credibility of online sources","Bookmarking and organizing web information" ]},
     {module:"Email and Communication Tools",
     moduleContents:["Email etiquette and organization","Using calendars, tasks, and contacts effectively","Introduction to collaboration tools like Slack or Microsoft Teams" ]},
     {module:"Basics of Information Security",
     moduleContents:["Understanding malware and antivirus software","Understanding malware and antivirus software","Basics of data encryption and password management" ]},
     {module:"Midterm Project and Review",
     moduleContents:["Midterm project: Create a comprehensive document/report including word processing, ","Midterm project: Create a comprehensive document/report including word processing, " ]},
     {module:"Capstone Project",
     moduleContents:["Students will plan and execute a project that incorporates the use of multiple computer applications to solve a practical problem or meet a real-world need.","Students will plan and execute a project that incorporates the use of multiple computer applications to solve a practical problem or meet a real-world need." ]},
     {module:"Finalizing Capstone Project and Documentation",
     moduleContents:["Completing the capstone project","Preparing documentation and a presentation on the project" ]},
     {module:"Final Examination",
     moduleContents:["A comprehensive examination covering all aspects of the course.","","","",""]},

     {module:"Capstone Project Presentation and Course Wrap-Up",
     moduleContents:["Students present their capstone projects to the class.","Q&A sessions and peer reviews.","Feedback from instructors and course wrap-up." ]},

    ],
  projectWork:[
    {title:"Project Proposal",desc:"Identify a problem and propose a solution that can be achieved by using computer applications learned in the course."},
    {title:"Implementation",desc:"Utilize word processing, spreadsheets, databases, presentations, and other relevant applications to create the project."},
    {title:"Documentation",desc:"Write comprehensive documentation explaining how the project was completed."},
    {title:"Presentation",desc:"Present the project to demonstrate the practical application of skills acquired in the course."},
  
    
],
projectIdeas:["","","","",""],
Evaluation:["Weekly Assignments: 20%","Midterm Project: 20%","Capstone Project: 20%","Participation and Attendance: 10%",""],
TextbookAndResources:["Textbooks on Microsoft Office or Google Workspace applications","Online tutorials and resources for software applications (e.g., Lynda.com, Udemy)","Access to software applications for hands-on practice" ],
courseDuration:"The course lasts for approximately 5-6 months, with weekly sessions totaling 4 hours each, leading to about 80-96 hours of instruction.",
CoursePolicies:["Consistent attendance and active participation are crucial.","Assignments and projects must be completed by specified deadlines.","Academic honesty is essential, and any form of dishonesty will result in disciplinary actions.","Collaboration is encouraged, but students must submit original work for evaluation." ],
summery:"By the end of the course, students will be proficient in using a variety of computer applications, enabling them to effectively manage different aspects of digital content creation, data management, and communication."

},




{img:fullstackdeveloper,
  name:"Full Stack Java Development ",
  courseDescription:"This course is designed to provide students with a comprehensive understanding of full stack development using Java technologies. It covers front-end technologies, Java programming, back-end development with Spring Boot, databases, and essential tools and practices for building and deploying web applications. By the end of the course, students will complete a capstone project that encompasses both front-end and back-end development to create a full-fledged web application.", 
  courseObjective:["Understand the fundamentals of web development and Java programming.","Learn front-end technologies like HTML, CSS, JavaScript, and a front-end framework.","Develop back-end services using Java and the Spring Framework.","Work with relational databases and SQL.","Implement RESTful services and API integration.","Utilize development tools and workflows such as Git, Maven, and Docker.","Build a full stack web application as a capstone project."],
  prerequisites:["Basic knowledge of programming concepts.","Familiarity with HTML and web technologies is helpful but not required" ], 
  courseOutline:[
    {module:"Introduction to Full Stack Development and Setup",
     moduleContents:["Overview of full stack development","Setting up the development environment (Java, IDEs, Git, etc.)","Introduction to web technologies and HTTP/HTTPS" ]},
    
    {module:"HTML, CSS, and Responsive Web Design",
     moduleContents:["HTML5 and semantic markup","CSS3, Flexbox, and Grid layouts","Implementing responsive design with media queries" ]},
     {module:"JavaScript and DOM Manipulation",
     moduleContents:["JavaScript syntax and ES6+ features","Manipulating the DOM","Event handling and asynchronous programming" ]},
     {module:"Advanced JavaScript and Front-End Frameworks",
     moduleContents:["Deep dive into callbacks, promises, and async/await","Introduction to front-end frameworks (React.js, Angular, or Vue.js)","Building a single-page application (SPA)" ]},
     {module:"Java Programming Language",
     moduleContents:["Java syntax and object-oriented programming","Collections, generics, and streams","Exception handling and I/O" ]},
     {module:"Midterm Review and Exam",
     moduleContents:["Review front-end technologies and basic Java concepts","Midterm exam covering HTML, CSS, JavaScript, and Java basics " ]},
     {module:"Introduction to Spring and Spring Boot",
     moduleContents:["Overview of the Spring Framework","Building a basic application with Spring Boot","Understanding Dependency Injection (DI)" ]},
     {module:"Building RESTful Web Services",
     moduleContents:["Creating REST controllers with Spring MVC","Error handling and validation","Documentation with Swagger/OpenAPI" ]},
     {module:"Data Access with Spring Data JPA",
     moduleContents:["Configuring a database with Spring Data JPA","CRUD operations using repositories","Introduction to query methods and JPQL" ]},
     {module:"Security and Testing in Spring",
     moduleContents:["Security and Testing in Spring","Configuring Spring Security","Writing unit and integration tests with JUnit and MockMvc" ]},
     {module:"Relational Databases and SQL",
     moduleContents:["Fundamentals of relational databases","Writing SQL queries: SELECT, INSERT, UPDATE, DELETE","Advanced SQL (joins, subqueries, transactions)" ]},
     {module:"Integrating Front-End and Back-End",
     moduleContents:["Connecting a front-end SPA with back-end services","Handling CORS and using HTTP clients","Introduction to web sockets for real-time communication" ]},
     {module:"Version Control, Build Tools, and DevOps Basics",
     moduleContents:["Advanced Git workflows","Advanced Git workflows","Introduction to containerization with Docker" ]},
     {module:"Review and Test",
     moduleContents:["Review database concepts and advanced back-end development","Review database concepts and advanced back-end development" ]},
     {module:"Full Stack Application Design",
     moduleContents:["Understanding full stack architecture","Planning the capstone project: requirements, design, technologies","Implementing Agile methodologies" ]},
     {module:"Capstone Project Development - Part 1",
     moduleContents:["Starting the capstone project: setting up the repository and project structure","Developing the database schema and backend API","Implementing the front-end interface and features" ]},
     {module:"Capstone Project Development - Part 2",
     moduleContents:["Continuing development of the capstone project","Integrating front-end and back-end components","Conducting user testing and debugging" ]},
     {module:"Capstone Project Deployment and Documentation",
     moduleContents:["Deploying the application to a cloud platform (e.g., AWS, Heroku)","Creating documentation for the project","Preparing a presentation of the project" ]},
     {module:"Final Examination",
     moduleContents:["A comprehensive final examination covering front-end and back-end technologies, frameworks, and development practices" ]},
     {module:"Capstone Project Presentation and Course Wrap-Up",
     moduleContents:["Presenting the capstone project to the class","Evaluation of the projects by instructors and peers","Feedback and course wrap-up" ]},
     


    
     
    ],
  projectWork:[
    {title:"Project Proposal",desc:"Students will decide on a project idea and create a proposal that outlines objectives, features, and the technology stack."},
    {title:"Development Phases",desc:"**: Design, implement, and test both front-end and back-end parts of the application."},
    {title:"Deployment",desc:"Deploy the web application to a cloud service"},
    {title:"Presentation and Documentation",desc:"Present the completed project and provide comprehensive documentation."},
    {title:"",desc:""},
    
],
projectIdeas:[""],
Evaluation:["Weekly Assignments: 20%","Midterm Exam: 15%","Practical Tests: 15%","Final Exam: 20%","Capstone Project: 25%","Participation and Attendance: 5%"],
TextbookAndResources:["Online tutorials and documentation (e.g., Mozilla Developer Network, Spring.io)","Books on HTML, CSS, JavaScript, Java, and Spring Framework","Access to software and tools required for development" ],
courseDuration:"The course lasts for approximately 5-6 months, with weekly sessions of 4 hours each.",
CoursePolicies:["Attendance is mandatory to ensure a thorough understanding of the course material.","Assignments, projects, and exams must be completed within the given deadlines.","Academic integrity must be maintained, with strict rules against plagiarism and cheating.","Active participation is encouraged to facilitate a collaborative learning environment." ],
summery:""

},


{img:ai,
  name:"Data Science and Artificial Intelligence ",
  courseDescription:"This course provides an in-depth study of data science and artificial intelligence, covering key concepts, tools, and techniques used for data analysis, machine learning, and AI. Students will learn to work with large datasets, apply statistical methods, build predictive models, and implement AI solutions. The program includes lectures, hands-on labs, quizzes, a midterm exam, a final exam, and a capstone project that will challenge students to solve a real-world problem using data science and AI methods.", 
  courseObjective:["Understand the fundamental principles of data science and artificial intelligence.","Gain proficiency in Python for data analysis and machine learning.","Learn to work with data using pandas, NumPy, and databases","Master data visualization with Matplotlib and Seaborn.","Build and evaluate machine learning models using Scikit-learn.","Explore neural networks and deep learning with TensorFlow and Keras.","Develop the skills to manage an end-to-end data science and AI project."],
  prerequisites:["Basic programming knowledge, preferably in Python.","Understanding of high school-level mathematics (algebra, probability, and statistics)."], 
  courseOutline:[
    {module:"Introduction to Data Science",
     moduleContents:["The role of a data scientist","Overview of the data science process","Overview of the data science process"]},
    
    {module:"Python for Data Science",
     moduleContents:["Python programming refresher","Introduction to pandas for data manipulation","Working with Series and DataFrames"]},
    
     {module:"Data Visualization",
     moduleContents:["Introduction to Matplotlib","Plotting with Seaborn for statistical graphics","Exploratory Data Analysis (EDA) practices"]},

     {module:"Statistical Foundations for Data Science",
     moduleContents:["Descriptive statistics and probability distributions","Inferential statistics and hypothesis testing","Correlation and regression analysis"]},

     {module:"Machine Learning Fundamentals",
     moduleContents:["Introduction to machine learning concepts","Supervised vs. unsupervised learning","Supervised vs. unsupervised learning" ]},

     {module:"Supervised Learning Algorithms",
     moduleContents:["Linear regression models","Classification with logistic regression, k-NN, and decision trees","Model evaluation and validation techniques" ]},

     {module:"Midterm Review and Exam",
     moduleContents:["Review of Python, statistics, and machine learning concepts","Midterm exam covering the first half of the course material" ]},
     
     {module:"Unsupervised Learning Algorithms",
     moduleContents:["Clustering with k-means and hierarchical clustering","Dimensionality reduction with PCA","Association rules and market basket analysis" ]},
     
     {module:"Advanced Model Evaluation",
     moduleContents:["Cross-validation strategies","Performance metrics for classification and regression","Handling imbalanced datasets" ]},
     
     {module:"Ensemble Learning and Model Tuning",
     moduleContents:["Random forests and boosting methods","Hyperparameter tuning with grid search and random search","Feature engineering and selection" ]},


     {module:"Introduction to Databases and Big Data",
     moduleContents:["SQL for data retrieval","NoSQL databases overview","Introduction to big data ecosystems (Hadoop, Spark)" ]},
      {module:"Introduction to Neural Networks",
     moduleContents:["Perceptrons and the concept of neural networks","Backpropagation and gradient descent","Building neural networks with TensorFlow and Keras" ]},
     {module:"Convolutional Neural Networks (CNNs)",
     moduleContents:["Understanding CNNs and their applications in image recognition","Implementing CNNs with Keras","Transfer learning and fine-tuning" ]},
     {module:"Recurrent Neural Networks (RNNs) and NLP",
     moduleContents:["Time series analysis and RNN basics","Long Short-Term Memory (LSTM) networks","Natural Language Processing (NLP) with RNNs" ]},
     {module:"Review and Test",
     moduleContents:["Review of neural networks and deep learning concepts","Practical test on implementing neural network models" ]},
     {module:"Special Topics in AI",
     moduleContents:["Introduction to reinforcement learning","Overview of Generative Adversarial Networks (GANs)","Ethics and implications of AI" ]},
     {module:"Ethics and implications of AI",
     moduleContents:["Case studies of data science and AI applications","Project management for data science and AI projects","Defining the capstone project scope and objectives" ]},
     {module:"Capstone Project Development - Part 1",
     moduleContents:["Data collection and preprocessing for the capstone project","Exploratory data analysis (EDA) and feature engineering","Exploratory data analysis (EDA) and feature engineering" ]},
     {module:"Capstone Project Development - Part 2",
     moduleContents:["Model refinement and hyperparameter tuning","Model evaluation and validation","Interpretation of results and findings" ]},
     {module:"Capstone Project Finalization and Documentation",
     moduleContents:["Finalizing the capstone project","Writing comprehensive documentation","Preparing for project presentation" ]},
     {module:"Final Examination",
     moduleContents:["A comprehensive final examination covering all course content" ]},
     {module:"Capstone Project Presentation and Course Wrap-Up",
     moduleContents:["Presentation of capstone projects to the class","Peer review and instructor feedback","Course summary and next steps for continued learning" ]},
  
    ],
  projectWork:[
    {title:"Project Proposal",desc:"Students identify a problem and propose a data-driven solution using data science and AI techniques"},
    {title:"Development Phases",desc:"Data collection, cleaning, EDA, model selection, training, evaluation, and deployment."},
    {title:"Presentation and Documentation",desc:"Present the project, including the methodology, results, and insights. Provide well-documented code and a final report."},
    
    
],
projectIdeas:["" ],
Evaluation:["Weekly Assignments: 20%","Midterm Exam: 15%","Practical Tests: 15%","Final Exam: 20%","Capstone Project: 25%","Participation and Attendance: 5%"],
TextbookAndResources:["Python for Data Analysis by Wes McKinney","Hands-On Machine Learning with Scikit-Learn, Keras, and TensorFlow by Aurélien Géron","Online courses and tutorials (Coursera, DataCamp, Kaggle)","Access to Python, Jupyter Notebooks, TensorFlow, Keras, and other data science tools"],
courseDuration:"The course is scheduled to last for approximately 5-6 months, with weekly 4-hour sessions.",
CoursePolicies:["Consistent attendance and active participation are required.","Assignments and projects are to be completed by specified deadlines.","Academic integrity must be maintained, and plagiarism will not be tolerated.","Students are encouraged to collaborate on learning but must submit their own work for assessments."],
summery:"Upon completion of the course, students will possess the necessary skills to pursue roles in data science and AI and be equipped to contribute meaningfully to data-driven projects across various industries."

},


{img:testing,
  name:"Automation and Manual Testing",
  courseDescription:"This comprehensive course covers the fundamental techniques and tools used in manual and automation testing of software. Students will learn test planning, design, execution, and management, as well as how to automate tests using popular tools and frameworks. Through lectures, hands-on labs, quizzes, a midterm exam, a final exam, and a capstone project, students will gain the expertise needed to ensure software quality in various development environments and platforms.", 
  courseObjective:["Understand the principles of software quality assurance and testing.","Learn the processes and techniques of manual testing.","Acquire skills in writing test cases and test scripts","Gain proficiency in automation testing tools and frameworks.","Develop an understanding of continuous integration and continuous testing.","Complete a real-world software testing project that includes both manual and automated tests." ],
  prerequisites:["Basic knowledge of software development and the software development lifecycle (SDLC).","Familiarity with at least one programming language is beneficial."], 
  courseOutline:[
    {module:"Fundamentals of Software Testing",
     moduleContents:["Overview of software testing and quality assurance","Testing principles and methodologies","The role of a software tester in the SDLC"]},
    
    {module:"Test Planning and Management",
     moduleContents:["Writing test plans and test cases","Test design techniques (boundary value analysis, equivalence partitioning, etc.)","Test management tools (e.g., JIRA, TestRail)"]},
    
     {module:"Manual Testing Execution and Reporting",
     moduleContents:["Performing functional testing, regression testing, and exploratory testing","Defect lifecycle and bug reporting","Writing effective bug reports and communicating with development teams" ]},

     {module:"Advanced Testing Types",
     moduleContents:["Non-functional testing: performance, security, usability, and accessibility testing","","Introduction to test environment setup and configuration","Test data management strategies"]},

     {module:"Version Control and Basic Programming Concepts",
     moduleContents:["Introduction to version control with Git","Basic programming concepts for testers (variables, loops, conditional statements)","Writing and executing simple scripts"]},

     {module:"Introduction to Automation Testing",
     moduleContents:["The need for test automation","Overview of Selenium WebDriver and its components","Setting up Selenium and writing basic automation scripts"]},

     {module:"Midterm Review and Exam",
     moduleContents:["Review manual and initial automated testing concepts","Midterm practical and written exam covering topics from the first two months"]},
     
     {module:"Advanced Selenium Techniques",
     moduleContents:["Handling web elements, waits, and exceptions in Selenium","Page Object Model (POM) for maintainable test scripts","Cross-browser testing with Selenium Grid"]},
     
     {module:"Frameworks and Unit Testing",
     moduleContents:["Introduction to test frameworks (JUnit, TestNG)","Writing and executing unit tests","Introduction to behavior-driven development (BDD) with Cucumber"]},
     
     {module:"API Testing",
     moduleContents:["Understanding RESTful services and API testing","Tools for API testing (Postman, RestAssured)","Writing automated tests for APIs"]},


     {module:"Mobile Testing",
     moduleContents:["Overview of mobile testing","Introduction to Appium for mobile automation","Challenges in mobile testing and how to address them" ]},
     {module:"Continuous Integration (CI) and Continuous Testing (CT)",
     moduleContents:["Introduction to CI/CD pipelines with Jenkins or similar tools","Integrating automated tests into CI/CD workflows","Continuous testing practices" ]},
     {module:"Advanced Automation Tools",
     moduleContents:["Overview of test automation frameworks (Robot Framework, Cypress)","Exploring visual regression testing tools (e.g., Percy, Screener)","Introduction to performance testing with JMeter" ]},
     {module:"Test Automation Strategy and Best Practices",
     moduleContents:["Building a scalable and maintainable test automation strategy","Test automation metrics and KPIs","Best practices for writing and organizing test code" ]},
     {module:"Review and Test",
     moduleContents:["Review of advanced test automation concepts","Practical test on automation skills"]},
     {module:"Special Topics in Software Testing",
     moduleContents:["Exploring accessibility testing tools and practices","Security testing fundamentals and tools (OWASP ZAP)","Introduction to AI in testing" ]},
     {module:"Test Automation Architecture and Design Patterns",
     moduleContents:["Understanding test automation architecture","Design patterns for test automation (Factory, Singleton, Strategy)","Refactoring and code reviews for test scripts" ]},
     {module:"Capstone Project Planning",
     moduleContents:["Selecting a project for the capstone","Defining scope, objectives, and deliverables for the project","Creating a test plan and strategy for the project" ]},
     {module:"Capstone Project Execution",
     moduleContents:["Implementing manual test cases for the project","Developing automated test scripts and integrating them into CI/CD","Executing tests, logging defects, and retesting" ]},
     {module:"Capstone Project Finalization and Documentation",
     moduleContents:["Finalizing tests and ensuring all objectives are met","Preparing test reports and documentation","Creating a presentation summarizing the project and its outcomes" ]},
     {module:"Final Examination",
     moduleContents:["Final Examination"  ]},
     {module:"Capstone Project Presentation and Course Wrap-Up",
     moduleContents:["Presentation of the capstone projects to the class","Peer and instructor evaluations","Course feedback and discussion of future learning paths" ]},
      
    ],
  projectWork:[
    {title:"Project Proposal",desc:"Students will choose a real-world application and plan a comprehensive testing strategy."},
    {title:"Project Proposal",desc:"Develop and execute manual test cases, documenting results"},
    {title:"Automation Testing Phase",desc:"Write and run automation scripts, integrate into CI/CD."},
    {title:"Final Documentation and Presentation",desc:"Document the testing process, findings, and present the project."},
    
    
],
projectIdeas:["" ],
Evaluation:["Weekly Assignments: 20%","Midterm Exam: 15%","Practical Tests: 15%","Final Exam: 20%","Capstone Project: 25%","Participation and Attendance: 5%"],
TextbookAndResources:["Selenium WebDriver: From Foundations to Framework by Yujun Liang & Alex Collins","Continuous Delivery: Reliable Software Releases through Build, Test, and Deployment Automation by Jez Humble and David Farley","Online forums and documentation (Stack Overflow, Selenium Documentation)"],
courseDuration:"The course is scheduled to last for approximately 5-6 months, with weekly sessions of 4 hours each.",
CoursePolicies:["Regular attendance and active participation are crucial for success.","Assignments and projects must be submitted by specified deadlines.","Academic integrity is critical; cheating and plagiarism will result in a failing grade.","Collaboration is encouraged, but all students must submit individual work." ],
summery:"By the end of the course, students will be proficient in both manual and automated testing and be prepared to take on roles as quality assurance professionals in the software industry."

},

{img:devops,
  name:"DevOps",
  courseDescription:"This course offers a comprehensive introduction to DevOps principles, practices, and tools. Students will learn about automating and integrating the processes between software development and IT teams to build, test, release, and manage software faster and more reliably. The curriculum includes lectures, hands-on labs, quizzes, a midterm exam, a final exam, and a project that will require students to apply DevOps practices in a real-world scenario.", 
  courseObjective:["Understand the DevOps culture and its impact on software development and operation lifecycle.","Learn key DevOps practices like continuous integration, continuous delivery, and infrastructure as code.","Gain proficiency in using various DevOps tools for version control, containerization, orchestration, monitoring, and more.","Implement a complete DevOps pipeline from development to production.","Complete a capstone project that encompasses setting up, deploying, and managing a full application stack."],
  prerequisites:["Basic knowledge of Linux/Unix command-line operations.","Familiarity with software development concepts and processes.","Understanding of basic system administration tasks."], 
  courseOutline:[
    {module:"Overview of DevOps",
     moduleContents:["Introduction to DevOps principles and practices","The history and necessity of DevOps","DevOps culture and mindset"]},
    
    {module:"Version Control Systems",
     moduleContents:["Introduction to Git and GitHub","Branching strategies and workflows","Collaboration and pull requests"  ]},
    
     {module:"Review and Practical Test",
     moduleContents:["Review of DevOps concepts and Git practices","Practical exercises and test on version control" ]},

     {module:"Continuous Integration (CI)",
     moduleContents:["CI concepts and benefits","Setting up Jenkins for automated builds","Integrating automated tests into the CI process" ]},

     {module:"Configuration Management Tools",
     moduleContents:["Introduction to configuration management and Infrastructure as Code","Basics of Ansible and its alternatives (Chef, Puppet)","Writing and running Ansible playbooks" ]},

     {module:"Containerization with Docker",
     moduleContents:["Understanding containerization and its benefits","Basics of Docker and container management","Building and running Docker containers" ]},
     {module:"Midterm Review and Exam",
     moduleContents:["Review of CI, configuration management, and containerization concepts","Midterm exam covering the first two months of the course material" ]},
     {module:"Continuous Delivery (CD)",
     moduleContents:["CD concepts and importance","Blue-green deployments and canary releases","Implementing a deployment pipeline using Jenkins" ]},
     {module:"Container Orchestration with Kubernetes",
     moduleContents:["Kubernetes architecture and concepts","Deploying applications on Kubernetes","Deploying applications on Kubernetes" ]},
     {module:"Infrastructure Automation",
     moduleContents:["Introduction to Terraform","Writing Terraform configurations for cloud infrastructure","Managing infrastructure as code" ]},
     {module:"Cloud Services and Providers",
     moduleContents:["Overview of cloud computing concepts","Introduction to AWS, Azure, and Google Cloud Platform","Basic cloud services: compute, storage, and networking" ]},
     {module:"Monitoring and Logging",
     moduleContents:["Monitoring infrastructure and applications","Tools like Prometheus and Grafana for monitoring","Centralized logging with ELK Stack (Elasticsearch, Logstash, Kibana)" ]},
     {module:"Microservices and Cloud-Native Applications",
     moduleContents:["Introduction to microservices architecture","Building cloud-native applications","Service mesh concepts and tools (Istio, Linkerd)" ]},
     {module:"DevSecOps",
     moduleContents:["Integrating security into the DevOps process","Tools and practices for application and infrastructure security","Security scanning and compliance" ]},
     {module:"Review and Test",
     moduleContents:["Review of monitoring, cloud-native tools, and DevSecOps","Practical exercises and test on the covered concepts" ]},
     {module:"DevOps Best Practices",
     moduleContents:["Effective communication and collaboration in DevOps","Review of key DevOps practices and principles","Case studies of successful DevOps implementation" ]},
     {module:"Preparing for the Capstone Project",
     moduleContents:["Identifying a project suitable for applying DevOps practices","Designing a DevOps strategy and workflow for the project","Planning project milestones and deliverables" ]},
     {module:"Capstone Project - Initial Setup and Development",
     moduleContents:["Setting up version control and CI/CD workflows","Automating infrastructure setup using configuration management tools","Containerizing elements of the application stack" ]},
     {module:"Capstone Project - Deployment and Orchestration",
     moduleContents:["Implementing complete CI/CD pipelines","Deploying to Kubernetes or another orchestration platform","Setting up monitoring and logging" ]},
     {module:"Capstone Project - Optimization and Documentation",
     moduleContents:["Optimizing deployments and ensuring high availability","Documenting the project setup and workflows","Preparing for project presentation" ]},

     {module:"Final Examination",
     moduleContents:["Final Examination"  ]},    
      {module:"Capstone Project Presentation and Course Wrap-Up",
     moduleContents:["Presentation of the capstone project to the class","Peer review and instructor feedback","Course feedback session and discussion of continued learning resources" ]},
    ],
  projectWork:[
    {title:"Project Proposal",desc:"Students propose a software application for which they will design and implement a full DevOps pipeline."},
    {title:"Development and Deployment",desc:"Set up version control, CI/CD, containerization, and orchestration for the application."},
    {title:"Monitoring and Logging",desc:"Implement monitoring and centralized logging for the deployed application."},
    {title:"Documentation and Presentation",desc:"Document the DevOps processes and present the project, detailing the setup and challenges encountered"},
  
    
],
projectIdeas:["" ],
Evaluation:["Weekly Assignments: 20%","Midterm Exam: 15%","Practical Tests: 15%","Final Exam: 20%","Capstone Project: 25%","Participation and Attendance: 5%"],
TextbookAndResources:["The DevOps Handbook: How to Create World-Class Agility, Reliability, & Security in Technology Organizations  by Gene Kim, Patrick Debois, John Willis, and Jez Humble","Online courses and tutorials (Linux Academy, Coursera, Pluralsight)","Access to DevOps tools (Jenkins, Docker, Kubernetes, Ansible, Terraform)"],
courseDuration:"The course is scheduled to last for approximately 5-6 months, with weekly sessions of 4 hours each.",
CoursePolicies:["Consistent attendance and active participation are expected.","Assignments, projects, and exams must be completed by the specified deadlines.","Students are expected to maintain academic integrity and honesty.","Collaboration is encouraged, but students must submit their own work for evaluation"],
summery:"Upon completion of the course, students will have a solid foundation in DevOps principles and practices and will be equipped with the skills needed to automate and improve the software development lifecycle in a range of environments."

},




{img:cloud,
  name:"Cloud Computing",
  courseDescription:"This course provides a comprehensive introduction to cloud computing, offering practical knowledge of cloud services, deployment, management, and security. Students will explore core cloud concepts and gain hands-on experience with leading cloud platforms such as AWS, Microsoft Azure, and Google Cloud Platform. The program includes lectures, lab sessions, quizzes, a midterm exam, a final exam, and a capstone project that involves deploying a scalable and resilient application in the cloud.", 
  courseObjective:["Understand the fundamental concepts of cloud computing and cloud service models (IaaS, PaaS, SaaS).","Learn about the deployment models (public, private, hybrid, community) and cloud architecture.","Gain practical skills in using cloud services for storage, computing, networking, and databases.","Explore cloud security, compliance, and best practices for cloud infrastructure management.","Develop a cloud-based application as part of a capstone project, demonstrating proficiency in design, deployment, and operation within the cloud."],
  prerequisites:["Basic understanding of networking, servers, and databases.","Familiarity with at least one programming language or scripting.","General IT knowledge or experience is helpful but not mandatory"], 
  courseOutline:[
    {module:"Cloud Computing Fundamentals",
     moduleContents:["Definition and characteristics of cloud computing","Cloud service models: IaaS, PaaS, SaaS","Cloud deployment models: public, private, hybrid, community" ]},
    
    {module:"Core Cloud Services",
     moduleContents:["Virtual machines and compute services","Storage options in the cloud","Networking basics and cloud load balancers"  ]},
    
     {module:"Introduction to AWS",
     moduleContents:["AWS account setup and management console","Key AWS services: EC2, S3, RDS, VPC","IAM: managing users, groups, and policies" ]},

     {module:"Advanced AWS Services",
     moduleContents:["Auto-scaling and Elastic Load Balancing","AWS Lambda and serverless architecture","Amazon DynamoDB and NoSQL services" ]},

     {module:"Introduction to Microsoft Azure",
     moduleContents:["Azure portal and core services","Azure Virtual Machines, Blob Storage, Azure SQL","Azure Active Directory and RBAC" ]},

     {module:"Azure Advanced Services",
     moduleContents:["Azure App Services and Azure Functions","Azure Cosmos DB and Table Storage","Azure Networking and Traffic Manager" ]},

     {module:"Midterm Review and Exam",
     moduleContents:["Review of core cloud concepts and AWS/Azure services","Midterm exam covering the first two months of course material" ]},
     
     {module:"Building Cloud-Native Applications",
     moduleContents:["Principles of cloud-native application design","Microservices and containerization with Docker","Continuous integration and continuous delivery (CI/CD)"]},
     
     {module:"Introduction to Google Cloud Platform (GCP)",
     moduleContents:["Google Cloud Console and key services","Google Compute Engine, Cloud Storage, Cloud SQL","Google Kubernetes Engine (GKE) and container orchestration" ]},
     
     {module:"GCP Advanced Services",
     moduleContents:["GCP serverless products: Cloud Functions, App Engine","Big data and machine learning services in GCP","GCP networking and security services" ]},


     {module:"Multi-Cloud Strategies and Solutions",
     moduleContents:["The rationale for multi-cloud strategy","Managing multi-cloud environments","Cloud brokerage and management platforms" ]},
     {module:"Cloud Security and Best Practices",
     moduleContents:["Cloud security principles and challenges","Data encryption and security tools","Compliance and regulatory considerations" ]},
     {module:"Cloud Monitoring and Optimization",
     moduleContents:["Monitoring and logging services across platforms","Resource and cost management strategies","Performance and optimization best practices" ]},
     {module:"Disaster Recovery and Business Continuity",
     moduleContents:["Disaster recovery (DR) strategies in the cloud","High availability and fault tolerance design","Backup and restore operations" ]},
     {module:"Review and Test",
     moduleContents:["Review of cloud security, compliance, and management","Practical exercises and test on the covered concepts" ]},
     {module:"Special Topics in Cloud",
     moduleContents:["Edge computing and its integration with the cloud","Introduction to hybrid clouds and on-premises integration","Building IoT solutions with cloud services" ]},
     {module:"Architecting Solutions in the Cloud",
     moduleContents:["Architectural frameworks and design patterns","Case studies: successful architectures on AWS, Azure, GCP","Selecting services for scalability, cost, and performance" ]},
     {module:"Capstone Project Planning",
     moduleContents:["Identifying a real-world problem to solve using cloud services","Designing a cloud solution architecture for the capstone project","Planning project milestones and deliverables" ]},
     {module:"Capstone Project Execution",
     moduleContents:["Implementing the project infrastructure in the cloud","Deploying applications and services using CI/CD pipelines","Configuring security, monitoring, and logging" ]},
     {module:"Capstone Project Finalization and Documentation",
     moduleContents:["Finalizing the project and ensuring all requirements are met","Preparing comprehensive documentation","Creating a presentation summarizing the project" ]},
     {module:"Final Examination",
     moduleContents:["A written and practical final examination covering all course content"  ]},
     {module:"Capstone Project Presentation and Course Wrap-Up",
     moduleContents:["Presentation of the capstone project to the class","Peer review and instructor feedback","Course feedback session and discussion of continued learning paths" ]},
      
    ],
  projectWork:[
    {title:"Project Proposal",desc:"Students propose a cloud solution that addresses a particular business or technical challenge."},
    {title:"Implementation",desc:"Set up cloud infrastructure, deploy applications, and configure necessary services."},
    {title:"Optimization",desc:"Implement monitoring, security, and cost-optimization measures "},
    {title:"Documentation and Presentation",desc:"Document the project setup and present the solution, highlighting innovation and problem-solving."},

    
],
projectIdeas:["" ],
Evaluation:["Weekly Assignments: 20%","Midterm Exam: 15%","Practical Tests: 15%","Final Exam: 20%","Capstone Project: 25%","Participation and Attendance: 5%"],
TextbookAndResources:["AWS Certified Solutions Architect Official Study Guide  by Joe Baron et al.","Azure for Architects  by Ritesh Modi","Google Cloud Platform for Architects  by Vitthal Srinivasan et al.","Online courses and tutorials (A Cloud Guru, Coursera)","Access to cloud platforms for hands-on practice"],
courseDuration:"The course is scheduled to last for approximately 5-6 months, with weekly sessions of 4 hours each",
CoursePolicies:["Consistent attendance and active participation are expected.","Assignments and projects must be completed by specified deadlines.","Students are expected to maintain academic integrity and honesty","Collaboration is encouraged, but students must submit their own work for evaluations",""],
summery:"Upon completion of the course, students will be well-versed in cloud computing concepts and will be equipped with the practical skills needed to architect, deploy, and manage cloud solutions across multiple platforms. They'll be prepared for roles such as cloud architect, cloud engineer, or cloud consultant."

},
  






{img:dataanalytics,
  name:"Data Analytics ",
  courseDescription:"This course focuses on equipping students with the skills necessary to analyze, interpret, and present data effectively. Throughout the course, students will learn to use a variety of tools and techniques to turn data into insights through hands-on instruction in data manipulation, statistical analysis, predictive modeling, and data visualization. The curriculum consists of lectures, lab sessions, quizzes, a midterm exam, a final exam, and a capstone project that challenges students to apply their learning to a real-world data analytics scenario.", 
  courseObjective:["Develop an understanding of the data analytics process and lifecycle.","Gain hands-on experience with data manipulation and cleaning techniques","Learn statistical methods for data analysis","Acquire skills in using analytics tools and software, including Excel, SQL, R, and Python.","Master the creation of compelling data visualizations and dashboards.","Apply machine learning techniques to predict future trends.","Complete a comprehensive data analytics project from data collection to presenting findings"],
  prerequisites:["Basic computer literacy and familiarity with spreadsheet tools.","Fundamental knowledge of statistics is helpful but not required.","Previous experience with any programming language is beneficial."], 
  courseOutline:[
    {module:"Data Analytics Fundamentals",
     moduleContents:["Overview of data analytics and its significance in business","The data analytics process: from data collection to decision-making","Introduction to analytical thinking and problem-solving" ]},
    
    {module:"Excel for Data Analytics",
     moduleContents:["Advanced Excel features for data analysis (pivot tables, advanced formulas)","Data cleaning and preparation in Excel","Basic data visualization using Excel charts and conditional formatting"  ]},
    
     {module:"Introduction to SQL",
     moduleContents:["SQL fundamentals (SELECT, FROM, WHERE, GROUP BY, HAVING)","Basic SQL queries for data retrieval","Introduction to relational database design" ]},

     {module:"Statistical Concepts for Analytics",
     moduleContents:["Descriptive statistics: measures of central tendency and variability","Distributions, sampling, and central limit theorem","Hypothesis testing and confidence intervals" ]},

     {module:"Intermediate SQL for Analyzing Data",
     moduleContents:["Aggregate functions and complex joins","Subqueries, CTEs, and window functions","Managing and querying large datasets" ]},

     {module:"Introduction to R for Data Analysis",
     moduleContents:["R programming basics and environment setup","Data manipulation with dplyr and tidyr","Basic statistical analysis and visualization with ggplot2" ]},

     {module:"Midterm Review and Exam",
     moduleContents:["Review of data analytics concepts, Excel, SQL, and basic R","Midterm exam covering the first half of the course material" ]},
     
     {module:"Data Visualization Principles",
     moduleContents:["Principles of effective data visualization","Designing dashboards and reports","Tools for visualization: Tableau and Power BI" ]},
     
     {module:"Predictive Analytics and Machine Learning",
     moduleContents:["Introduction to predictive analytics and machine learning concepts","Building simple linear regression models","Classification techniques for predictive modeling" ]},
     
     {module:"Advanced R for Data Analytics",
     moduleContents:["Advanced data manipulation and preparation techniques","Building multiple regression models in R","Introduction to time series analysis"  ]},


     {module:"Python for Data Analytics",
     moduleContents:["Setting up the Python environment (Anaconda, Jupyter Notebooks)","NumPy for numerical computing and pandas for data analysis","Basic data visualization with Matplotlib and Seaborn" ]},
     {module:"Advanced Python for Data Analytics",
     moduleContents:["Advanced data manipulation with pandas","Automating data cleaning processes","Integrating Python with SQL databases"  ]},
     {module:"Machine Learning with Python",
     moduleContents:["Scikit-learn for machine learning","Building and evaluating predictive models (decision trees, random forests)","Model selection and cross-validation techniques"  ]},
     {module:"Handling Big Data and Introduction to Big Data Tools",
     moduleContents:["Introduction to big data concepts and ecosystems","Overview of Hadoop, Spark, and cloud-based big data solutions","Introductory concepts of working with big data in Python"  ]},
     {module:"Review and Test",
     moduleContents:["Review of advanced data analytics concepts and machine learning","Practical exercises and test on Python data analytics tools"   ]},
     {module:"Special Topics in Data Analytics",
     moduleContents:["Text analytics and natural language processing basics","Introduction to web scraping and data collection from APIs","Ethics in data and privacy considerations"  ]},
     {module:"Data Storytelling and Presentation Skills",
     moduleContents:["The art of data storytelling","Designing compelling presentations and interactive visualizations","Tools and techniques for effective data communication"  ]},
     {module:"Capstone Project Planning",
     moduleContents:["Identifying a problem statement for the capstone project","Creating a project proposal and outlining the analytical approach","Planning for data collection, analysis, and presentation of findings"  ]},
     {module:"Capstone Project Execution",
     moduleContents:["Data collection and cleaning for the project","Conducting exploratory data analysis (EDA)","Applying statistical methods and building predictive models"  ]},
     {module:"Capstone Project Finalization and Documentation",
     moduleContents:["Finalizing the analytics report with findings and insights","Creating visualizations to support the story","Preparing for the presentation of the project"  ]},
     {module:"Final Examination",
     moduleContents:["A comprehensive written and practical final examination covering all course content"   ]},
     {module:"Capstone Project Presentation and Course Wrap-Up",
     moduleContents:["Presentation of the capstone project to the class","Peer review and instructor feedback","Course feedback and discussion of next steps in data analytics careers"  ]},
      
    ],
  projectWork:[
    {title:"Project Proposal",desc:"Identify a business or research question and propose a data-driven approach to address it."},
    {title:"Data Collection and Cleaning",desc:"Gather required data, clean, and prepare it for analysis."},
    {title:"Analysis and Modeling",desc:"Conduct EDA, apply statistical methods, and build predictive models."},
    {title:"Presentation and Documentation",desc:"Present findings in a clear, engaging manner, and document the project process and outcomes."} 
    
],
projectIdeas:["","","","",""],
Evaluation:["Weekly Assignments: 20%","Midterm Exam: 15%","Practical Tests: 15%","Final Exam: 20%","Capstone Project: 25%","Participation and Attendance: 5%"],
TextbookAndResources:["Storytelling with Data  by Cole Nussbaumer Knaflic","Python for Data Analysis  by Wes McKinney","R for Data Science  by Hadley Wickham and Garrett Grolemund","Online courses and resources for R, Python, SQL, Tableau, and Power BI" ],
courseDuration:"The course is scheduled to last for approximately 5-6 months, with weekly sessions of 4 hours each",
CoursePolicies:["Regular attendance and active participation are essential for success.","Assignments and projects must be submitted by specified deadlines.","Academic integrity must be maintained, and plagiarism will result in a failing grade.","Collaboration is encouraged, but students must submit their own work for evaluations" ],
summery:"Upon completion of the course, students will have the skills to carry out thorough data analyses, build predictive models, and effectively communicate actionable insights to stakeholders. They will be prepared for roles such as data analyst, business analyst, and data scientist."

},


  // {img:cpp,name:"jgv++"},
  // {img:java,name:"JAVA"},
  // {img:python,name:"PYTHON"},
  // {img:sql,name:"SQL"},
  // {img:php,name:"PHP"},
  // {img:net,name:".NET"}

];







function Postgraduate() {
const navigate=useNavigate();
const { setstate } = useCourseContext()


const handleViewDetails = (ele) => {
  // Use navigate to redirect to the CourseDetail page and pass the data
setstate(ele)
  navigate('/courseDetails');
};
  return (
    <div id="courses">
      <div className="m-auto" style={{width:'80%'}}>
<div className="mt-5"><h5 class="intro">Introduction</h5></div>
<div><h2>Welcome to Post Graduate Courses</h2></div></div>
 <div className="d-flex justify-content-center flex-wrap" style={{width:'80%', margin:'auto'}}>
  {store3.map((ele)=>(
    <Card className="crds" style={{ width: '15rem', margin :'20px',borderRadius:'10px',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'}}>
    <Card.Img class="cardImg3" variant="top" src={ele.img} />
    <Card.Body>
      <Card.Title style={{}}>{ele.name}</Card.Title>
      <Card.Text>
       {ele.courseObjective[1]}       
      </Card.Text>
      <Button variant="primary" onClick={()=>handleViewDetails(ele)}>Know More</Button>
     
    </Card.Body>
  </Card>
  ))}

</div>

    </div>


  );
}

export { Postgraduate };