import React from "react"
import { useCourseContext } from "../context/courseContextProvider"
import { Card, Col, Container, Row } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
import "./courseDetails.css"
import bgimg from "../img/bgimgblur2.jpg"
const CourseDetails = () => {
    const { state } = useCourseContext();
    return <>

        <Container fluid >
            <Row class="bgimg" style={{backgroundImage: `url(${bgimg})`, padding:'20px',marginTop:'25px'}}>

                <Row style={{ width: '90%', margin: 'auto' }}>
                    <Col style={{width:'70%'}}>
                        <div  ><Row className="mt-4"><h2 class="h2">{state.name}</h2></Row></div>

                        <div  style={{ width: '100%' }}>
                            <Row><h5 class="para2"  >{state.courseDescription}</h5></Row>
                            <div ><Row className="mt-4"><h2 class="h2">Courses Objective</h2></Row></div>

                            <Row>
                                {state.courseObjective.map((ele, index) => {
                                    return (
                                        <h5 class="para3" key={index}><li>{ele}</li></h5>
                                    )
                                })}
                            </Row>

                        </div>
                    </Col>
                    <Col sm={5} id="cdpage">
                        <Card className="crds2" style={{ margin: '15px', borderRadius: '10px' }}>
                            <Card.Img class="card2Img" variant="top" src={state.img} />
                            <Card.Body>
                                <Card.Title >{state.name}</Card.Title>
                                <Card.Text>
                                    <Row><h4 class="fees">Visit Our Nearest Branch <br/>And Discuss The Fees Structure</h4></Row>
                                    <Row> <h6>Course Fee is applicable all over India</h6></Row>
                                    <hr />
                                    <Row > <h4>This  course includes:</h4></Row>
                                   
                                    <Row > <Col >Language: English</Col></Row>
                                    <Row> <Col >Duration: 120 hours (2 months)</Col></Row>
                                    <Row> <Col >Learning Mode: Center</Col></Row>
                                    <Row> <Col >Certificate of Completion</Col></Row>
                                  
                                    <hr />
                                    <Row ><h4>Eligibility</h4></Row>
                                    <Row ><Col >Learner should preferably a std. 10th Pass student (Not Compulsory)</Col></Row>
                                    <Row ><Col >It is desirable that Learner should have done MS-CIT Course (Not Compulsory)</Col></Row>
                                    <hr />
                                    <Row><Col class="para2">Duration :120 hours (2 Months)</Col></Row>
                                    <Row> <Col class="para2">Certificate of Completion</Col></Row>


                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </Col></Row></Row>

            <Row>
                <Col xs={8} className="m-auto">
                    <div ><Row className="mt-5"><h2 class="h2b">Prerequisites</h2></Row></div>
                    <Row>{state.prerequisites.map((ele, index) => { return (<h5 class="para4" key={index}><li>{ele}</li></h5>) })}</Row>
                </Col>
            </Row>



          


            <Row>
                <Col xs={8} className="m-auto">
                <div className="mt-5"><h2 class="h2b">Course Outline</h2></div>
                <Accordion>
      
      {state.courseOutline.map((ele, index) => { 
                         return (<Accordion.Item eventKey={index}>
                          <Accordion.Header><h5 class="h2b">{ele.module}</h5></Accordion.Header>
                         {ele.moduleContents.map((elem,i)=>{
                            return(
                                <Accordion.Body>
                               <h5 class="para5" key={i}><li> {elem}</li></h5>
                                </Accordion.Body>
                            )
                         })}
                          </Accordion.Item>
                         ) })}
        
        
          
      
    </Accordion>
                   

                </Col>
            </Row>


            

        </Container>

    </>
}

export { CourseDetails }