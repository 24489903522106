import { Row,Col,Card,Form,Button } from "react-bootstrap";
import emailimg from "../img/email.png"
import contactimg from "../img/contact.png"
import locationimg from "../img/location.png"
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import  "./contact.css"
function Contact() {

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('service_45b7n2a', 'template_1e9devd', form.current, 'XWp4u_Db2AjAGPr_p')
        .then((result) => {
            console.log(result.text);
            alert("message sent");
        }, (error) => {
            console.log(error.text);
            alert("error");
        });
  
       
    };
    return (
    <div style={{}}>
  <Row style={{width:'80%',margin:'25px auto',textAlign:'center'}}>
    <Col class="contactCard">
    <Card className="crds3" style={{ width: '95%',height:'90%', margin :'20px',borderRadius:'10px',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'}}>
    <Card.Img class="cardImg2" variant="top" src={emailimg} />
    <Card.Body>
      <Card.Title><h2>Email</h2></Card.Title>
      <Card.Text>
      enquiry@iicets.com
      </Card.Text>
       </Card.Body>
  </Card>
    </Col>
    <Col>
    <Card className="crds3" style={{ width: '95%',height:'90%', margin :'20px',borderRadius:'10px',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'}}>
    <Card.Img class="cardImg2" variant="top" src={locationimg} />
    <Card.Body>
      <Card.Title><h2>Address</h2></Card.Title>
      <Card.Text>
      M2Q3+5FG, Khalcha Rasta, Raviwar Peth, Satara, Maharashtra 415001, India
      </Card.Text>
       </Card.Body>
  </Card></Col>

    <Col>
    <Card className="crds3" style={{ width: '95%',height:'90%', margin :'20px',borderRadius:'10px',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'}}>
    <Card.Img class="cardImg2" variant="top" src={contactimg} />
    <Card.Body>
      <Card.Title><h2>Contact</h2></Card.Title>
      <Card.Text>
      <p > 8485819119 <br /> 7517819119<br/> 7757898255</p>
            
      </Card.Text>
       </Card.Body>
  </Card>
  </Col>
  </Row>
<div style={{padding:'20px', }}>


  <Row id="contactform">
    <Row><h2>Write us Message</h2></Row>
    <Row> <Form ref={form} onSubmit={sendEmail}> 
   <Row className="mb-3">
        <Col>
        <Form.Label>Your Name</Form.Label>
          <Form.Control placeholder="" name="user_name"/>
        </Col>
        
      </Row>
     <Row className="mb-3">
        <Col>
        <Form.Label>Your Email</Form.Label>
          <Form.Control placeholder="" name="user_email"/>
        </Col>
        
      </Row>

      <Row className="mb-3">
        <Col>
        <Form.Label>Message</Form.Label>
          <textarea className="form-control" rows="4" placeholder="" name="message"/>
        </Col>
        
      </Row>
    
      {/* <Button type="submit" value="Send" /> */}
      <Button variant="primary" style={{width:'100%'}} className='mt-4 "btn btn-primary btn-lg' value="Send" type="submit">
        Submit
      </Button>
    </Form></Row>

  </Row>
  </div>
  <Row style={{width:'70%',margin:'25px auto',textAlign:'center'}}> 

    <Row><h2>Google Location</h2></Row>
    <Row  style={{height:'300px',marginBottom:'100px'}}>
        
        {/* <iframe class="embed-responsive-item" src="https://maps.google.com/maps?q=satara=&output=embed" scrolling="no" marginheight="0" marginwidth="0"></iframe> */}
    <iframe width="600" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=HB Tower, Khalcha Rasta, Raviwar Peth, Satara, Maharashtra 415001=&output=embed" ></iframe>
 
    
    </Row>
    
  </Row>
      </div>
    );
  }
  

  export {Contact};