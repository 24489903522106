import accenture from "../img/company/accenture.png"
import amazon from "../img/company/amazon.png"
import capgemini from "../img/company/capginimi.png"
import globant from "../img/company/globamt.png"
import google from "../img/company/google.png"
import hcl from "../img/company/hcl.png"
import hypersystem from "../img/company/hypersystem.jpg"
import infosys from "../img/company/infosys.png"
import microsoft from "../img/company/microsoft.png"
import mindtree from "../img/company/mindtree.png"
import oracle from "../img/company/oracle.png"
import redington from "../img/company/redington.png"
import tcs from "../img/company/tcs.png"
import techmahindra from "../img/company/techmahindra.png"
import walmart from "../img/company/walmart.png"
import wipro from "../img/company/wipro.png"
import lnt from "../img/company/l&t.jpg"
import mphasis from "../img/company/mphasis.png"
import ibm from "../img/company/ibm.png"
import cognizent from "../img/company/cognizant.jpg"


import { Card } from "react-bootstrap"

let placemntstore=[
  {img:accenture,name:"Accenture"},
  {img:amazon,name:"Amazon"},
  {img:capgemini,name:"Capgimemini"},
  {img:cognizent,name:"Cognizant"},

  {img:globant,name:"Globant"},
  {img:google,name:"Google"},
  {img:hcl,name:"HCL"},
  {img:hypersystem,name:"Hyperlink Infosystem"},
  {img:ibm,name:"IBM"},
  {img:infosys,name:"Infosys"},
  {img:lnt, name:"L&T Infotech"},
  {img:microsoft,name:"Microsoft"},
  {img:mindtree,name:"Mindtree"},
  {img:mphasis,name:"Mphasis"},
  {img:oracle,name:"Oracle"},
  {img:redington,name:"Redington"},
  {img:tcs,name:"TCS"},
  {img:techmahindra,name:"Tech Mahindra"},
  {img:walmart,name:"Walmart"},
  {img:wipro,name:"Wipro"},
 
 

]


function Placements() {
  return (
    <div id="courses">
      <div className="m-auto " style={{width:'80%'}}>
<div><h2 className="mt-5">Our Top Hiring partner</h2></div></div>
 <div className="d-flex justify-content-center flex-wrap" style={{width:'80%', margin:'auto'}}>
  {placemntstore.map((ele)=>(
    <Card className="crds3" style={{ width: '12rem', margin :'20px',borderRadius:'10px',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'}}>
    <Card.Img class="cardImg" variant="top" src={ele.img} />
    <Card.Body>
      <Card.Title className="text-secondary">{ele.name}</Card.Title>
      <Card.Text>
    
       
      </Card.Text>
     
    </Card.Body>
  </Card>
  ))}

</div>

    </div>


  );
  }
  
  export { Placements };