import { Button,Card } from "react-bootstrap";
import logo from "../img/gtr.jpeg"
import "./courses.css"
import c from "../img/c.png"
import cpp from "../img/c++.png"
import java from "../img/java.png"
import python from "../img/python.png"
import sql from "../img/sql.png"
import php from "../img/php.png"
import net from "../img/net.png"
import { useNavigate } from "react-router-dom";
import tally from "../img/tally.png"
import { useCourseContext } from "../context/courseContextProvider";
import fullstack from "../img/fullstack.png"
const store2=[
  {img:c,
    name:"C Programming",
    courseDescription:"This course introduces students to the fundamentals of computer programming using the C language. Students will learn the principles of problem-solving, program design, and structured programming. The course covers basic data types, operators, control structures, functions, arrays, pointers, and the use of libraries. By the end of the course, students will develop a comprehensive project that demonstrates their understanding of C programming concepts.", 
    courseObjective:["Understand the basics of the C programming language.","Develop problem-solving skills in a programming context.","Learn to write, compile, and debug C programs.","Grasp the use of different data types, operators, and control structures.","Understand and apply the concepts of functions, arrays, and pointers.","Learn to use standard library functions and create modular code.","Develop a complete project from conception to implementation in C."],
    prerequisites:["Basic understanding of computers and their operation.","Familiarity with any programming language is beneficial but not required."], 
    courseOutline:[
      {module:"Introduction to Programming and C",
       moduleContents:["Overview of programming languages","History and characteristics of C","Setting up the development environment","Structure of a C program","Compiling and running a C program"]},
      
      {module:"Variables, Data Types, and Operators",
       moduleContents:["Variables and data types (int, char, float, double)","Constants and literals","Basic operators (arithmetic, relational, logical, bitwise)","Expressions and statements","Type conversions and typecasting"]},
      
       {module:"Control Structures",
       moduleContents:["Conditional statements (if, if-else, switch)","Loops (for, while, do-while)","Nested control structures","Break and continue statements","Introduction to goto"]},

       {module:"Functions",
       moduleContents:["Defining and calling functions","Function arguments and return values","Scope and lifetime of variables","Recursive functions","Passing arguments by value and reference"]},

       {module:"FuArrays and Stringsnctions",
       moduleContents:["Understanding arrays","Multi-dimensional arrays","Operations on arrays (searching, sorting)","Introduction to strings and string handling functions","String manipulation in C (strcpy, strlen, strcat, strcmp)"]},

       {module:"Pointers",
       moduleContents:["Basics of pointers","Pointer arithmetic","Pointers and arrays","Pointers to pointers and their applications","Dynamic memory allocation (malloc, calloc, realloc, free)"]},

       {module:"Structures, Unions, and Enumerations",
       moduleContents:["Defining and using structures","Pointers to structures","Unions and their usage","Enumerations (enum) and typedef"]},
       
       {module:"File I/O and Preprocessor Directives",
       moduleContents:["File handling in C (fopen, fclose, fread, fwrite, fprintf, fscanf)","Reading from and writing to files","Preprocessor directives (#include, #define, #ifdef, #ifndef)","Conditional compilation","Macros and their usage"]},
       
       {module:"Error Handling",
       moduleContents:["Understanding errors and debugging","Using debugger tools","Runtime error handling","Asserts and static analysis"]},
       
       {module:"Advanced Topics",
       moduleContents:["Command-line arguments","Variable argument functions (stdarg.h)","Working with libraries (static and dynamic)","Introduction to linked lists"]},


       {module:"Standard Library",
       moduleContents:["Overview of C Standard Library","Commonly used library functions (stdlib.h, string.h, math.h, etc.)","Understanding and using the qsort and bsearch functions"]}
      ],
    projectWork:[
      {title:"Project Conceptualization",desc:"Discuss and decide on a project topic relevant to real-world problems."},
      {title:"Project Planning",desc:"Define project scope, design algorithms, and draft a high-level architecture."},
      {title:"Testing and Debugging",desc:"Test the project for bugs, edge cases, and performance issues and fix them."},
      {title:"Documentation",desc:"Document the project, including code comments, user manual, and developer notes."},
      {title:"Presentation",desc:"Prepare to present the project to the class, highlighting key learning points and challenges overcome."},
      
  ],
  projectIdeas:["A simple file compressor/decompressor.","A personal finance manager (ledger system).","A command-line based tic-tac-toe game.","A library management system.","A contacts management system","A console-based student record management system."],
  Evaluation:["Assignments and Homework: 30%","Quizzes and Tests: 20%","Project: 30%","Final Exam: 20%"],
  TextbookAndResources:["The C Programming Language by Brian W. Kernighan and Dennis M. Ritchie","Online tutorials and documentation","Integrated Development Environments (IDEs) like Code::Blocks or Visual Studio Code","GCC Compiler"],
  courseDuration:"The course is typically conducted over a 12-week period, with 3 hours of instruction per week.",
  CoursePolicies:["Attendance is mandatory for all lectures and lab sessions","Assignments and projects must be submitted by the deadlines specified.","Plagiarism in any form will not be tolerated and will result in a failing grade for the course."],
  summery:"By the end of the course, students will have a solid foundation in C programming and will be equipped with the skills to undertake more advanced studies in computer science or develop their own C-based projects."

},


{img:cpp,
  name:"C++ Programming",
  courseDescription:"This course is designed to introduce students to the C++ programming language, a powerful object-oriented extension of the C language. Students will learn about fundamental programming concepts, object-oriented programming principles, standard library usage, template programming, and exception handling. By the end of the course, students will apply their knowledge to a comprehensive project that demonstrates their proficiency in C++ programming.", 
  courseObjective:["Understand the syntax and semantics of the C++ language.","Develop problem-solving strategies and algorithmic thinking.","- Explore object-oriented programming concepts such as encapsulation, inheritance, and polymorphism.","Utilize the Standard Template Library (STL) for efficient programming.","Handle errors and exceptions in a robust manner.","Design, implement, and manage complex projects in C++."],
  prerequisites:["Basic understanding of programming concepts, preferably in C or another procedural programming language.","Familiarity with concepts like variables, control structures, and basic input/output."], 
  courseOutline:[
    {module:"Introduction to C++",
     moduleContents:["History and features of C++","Differences between C and C++","C++ development environments and tools","Structure of a C++ program","Compiling and running C++ programs"]},
    
    {module:"Basic Syntax and Data Types",
     moduleContents:["Variables, data types, and type modifiers","Constants, enumerations, and typedef","Namespaces and the `std` namespace","Basic input/output with `cin` and `cout`","Operators and expressions"]},
    
     {module:"Control Structures and Functions",
     moduleContents:["Conditional statements (`if`, `switch`)","Loops (`for`, `while`, `do-while`)","Functions, function overloading, and default arguments","Inline functions and the `inline` keyword","Scope, storage classes, and recursion"]},

     {module:"Object-Oriented Programming Basics",
     moduleContents:["Classes and objects","Encapsulation, access specifiers, and data hiding","Constructors and destructors","`this` pointer and its uses","`this` pointer and its uses"]},

     {module:"Operator Overloading and Friend Functions",
     moduleContents:["Overloading unary and binary operators","Overloading the assignment operator","Friend functions and friend classes","Overloading the stream insertion and extraction operators"]},

     {module:"Inheritance and Polymorphism",
     moduleContents:["Base classes and derived classes","Types of inheritance","Function overriding and virtual functions","Abstract classes and pure virtual functions","Polymorphism and dynamic binding"]},

     {module:"Templates and Exception Handling",
     moduleContents:["Function templates and class templates","Template specialization","Standard exceptions and custom exceptions","Try, catch, and throw blocks","Exception propagation and rethrowing"]},
     
     {module:"The Standard Template Library (STL)",
     moduleContents:["Introduction to STL components","Containers (vector, list, set, map, etc.)","Iterators and their types","Algorithms (sort, find, accumulate, etc.)","Function objects and lambda expressions"]},
     
     {module:"Advanced Topics",
     moduleContents:["Dynamic memory management with `new` and `delete`","Copy constructors and the rule of three","Move semantics and the rule of five","Smart pointers (`unique_ptr`, `shared_ptr`, `weak_ptr`)","Type casting operators (static_cast, dynamic_cast, reinterpret_cast, const_cast)"]},
     
     {module:"File I/O",
     moduleContents:["File stream classes (`ifstream`, `ofstream`, `fstream`)","File opening modes","Sequential and random access in files","Binary files and serialization"]},


     {module:"Advanced Object-Oriented Programming",
     moduleContents:["Multiple inheritance and virtual base classes","Composition vs inheritance","Operator overloading for complex data types","The `friend` keyword for class interoperability"]}
    ],
  projectWork:[
    {title:"Project Proposal",desc:"Students will submit a project proposal that outlines the problem statement, objectives, methodologies, and tools to be used."},
    {title:"Project Development",desc:"Students will apply the concepts learned to design, code, and test a C++ application."},
    {title:"Project Documentation",desc:"Comprehensive documentation will cover code explanation, user manuals, and design rationales"},
    {title:"Project Presentation",desc:"Students will demonstrate their application and discuss challenges faced and how they were overcome."},
    
    
],
projectIdeas:["A graphical calculator application using C++ and a graphics library.","A simple social media platform with user profiles and messaging capabilities.","A text-based adventure game with inventory and combat systems.","A database management system for a small business.","An educational tool for learning and practicing data structures and algorithms."],
Evaluation:["Weekly Assignments: 25%","Midterm Examination: 20%","Project: 35%","Final Examination: 20%"],
TextbookAndResources:["C++ Primer by Stanley B. Lippman, Josée Lajoie, and Barbara E. Moo","C++ online documentation","C++ online forums and communities","Development environments such as Visual Studio, Code::Blocks, or Eclipse"],
courseDuration:"The course is typically conducted over a 14-week period, with 3 hours of instruction per week.",
CoursePolicies:["Regular class attendance and participation are crucial.","Late submissions of assignments may result in a grade penalty, unless due to documented emergencies.","Academic honesty is expected; cases of cheating or plagiarism will result in disciplinary action.","Students are encouraged to collaborate on assignments, but must submit individual work unless otherwise instructed."],
summery:"Upon completion of the course, students will have a strong grasp of C++ programming and will be prepared for advanced study in computer science or software development roles that require strong C++ skills."

},
{img:java,
  name:"Java Programming",
  courseDescription:"This course provides a comprehensive overview of Core and Advanced Java programming, focusing on the foundational skills necessary to build desktop, web, and mobile applications. Students will learn the essentials of Java syntax and semantics, object-oriented programming, graphical user interfaces, networking, multithreading, database connectivity, and web development with Java technologies. The course concludes with a capstone project that integrates the concepts and skills learned.", 
  courseObjective:["Understand and apply object-oriented principles in Java.","Understand and apply object-oriented principles in Java."," Work with I/O streams, serialization, and networking in Java.","Utilize multithreading and concurrency for efficient program execution.","Access and manipulate databases using JDBC.","Explore web development with Java using Servlets, JSP, and frameworks.","Complete a comprehensive project showcasing skills in both Core and Advanced Java."],
  prerequisites:["Basic knowledge of programming concepts (variables, loops, control flow).","Familiarity with any programming language is helpful but not required."], 
  courseOutline:[
    {module:"Introduction to Java",
     moduleContents:["Overview of Java and its features","Java Development Kit (JDK) and Java Runtime Environment (JRE)","Setting up the Java development environment","Basic Java syntax and programming conventions","Writing your first Java program"]},
    
    {module:"Object-Oriented Programming (OOP) in Java",
     moduleContents:["Classes and objects","Constructors and methods","Inheritance, abstraction, polymorphism, and encapsulation","Interfaces and abstract classes","Inner classes and anonymous classes"]},
    
     {module:"Java Language Essentials",
     moduleContents:["Data types, variables, and arrays","Operators and control flow statements","Exception handling and assertions","Utilizing core classes in `java.lang` and `java.util`","Collections framework"]},

     {module:"Input/Output (I/O) and Serialization",
     moduleContents:["Byte and character streams","File handling and I/O streams (`FileInputStream`, `FileOutputStream`, `BufferedReader``BufferedWriter`, etc.)","Serialization and deserialization","Working with properties files and preferences"]},

     {module:"Java GUI Programming",
     moduleContents:["Introduction to Swing components","Event handling and layouts","Introduction to JavaFX and scene graph","Creating interactive applications with JavaFX","Using Scene Builder to design user interfaces"]},

     {module:"Networking in Java",
     moduleContents:["Sockets and server sockets","Developing client-server applications","URL, URLConnection, HttpURLConnection","Java Remote Method Invocation (RMI)"]},

     {module:"Multithreading and Concurrency",
     moduleContents:["The Thread class and Runnable interface","Lifecycle of a thread and thread synchronization","Concurrent collections and thread-safe data structures","Executor framework and thread pools","Understanding Java Memory Model (JMM)"]},
     
     {module:"Database Access with JDBC",
     moduleContents:["Introduction to relational databases and SQL","JDBC architecture and API","Connecting to databases using JDBC","Executing SQL statements and processing result sets","Best practices for database programming in Java"]},
     
     {module:"Java Enterprise Edition (Java EE) Overview",
     moduleContents:["Overview of Java EE and its components","Understanding web architectures","Introduction to servlets and JavaServer Pages (JSP)","JavaBeans and custom tags in JSP","Overview of Java EE design patterns"]},
     
     {module:"Advanced Java Technologies",
     moduleContents:["Java Naming and Directory Interface (JNDI)","Java Message Service (JMS)","Web services and Java API for RESTful Web Services (JAX-RS)","Introduction to Java frameworks (Spring, Hibernate)"]},


     {module:"Java Design Patterns and Best Practices",
     moduleContents:["Understanding design patterns in Java (Singleton, Factory, Observer, etc.)","Writing clean, maintainable, and efficient code","Review of Java development best practices","Code versioning with tools like Git"]}
    ],
  projectWork:[
    {title:"Project Proposal",desc:"Submission of an initial project idea with objectives, expected outcomes, and technologies to be used."},
    {title:"Project Analysis and Design",desc:"Analyzing requirements, designing class diagrams, and defining project architecture."},
    {title:"Project Implementation",desc:"Coding the application using Java SE and Java EE technologies."},
    {title:"Project Testing",desc:"Unit testing, integration testing, and debugging."},
    {title:"Project Deployment",desc:"Deploying the application on a suitable platform or server."},
    {title:"Project Presentation and Documentation",desc:"Presenting the project to the class and submitting comprehensive documentation, including a user guide and developer notes."},
    
],
projectIdeas:["An e-commerce web application with shopping cart functionality.","A chat application using Java sockets and a GUI.","A hotel management system with a database backend.","A RESTful web service for managing books in a library.","An online survey system with dynamic form creation."],
Evaluation:["Assignments and Labs: 30%","Midterm Examination: 20%","Project: 30%","Final Examination: 20%"],
TextbookAndResources:["Java: The Complete Reference by Herbert Schildt","Effective Java by Joshua Bloch","Online Java documentation and resources","Integrated Development Environment (IDE) such as IntelliJ IDEA or Eclipse"],
courseDuration:"The course is typically conducted over a 16-week period, with 4 hours of instruction per week.",
CoursePolicies:["Attendance and active participation are mandatory for all scheduled classes.","Late submissions of assignments may result in a grade reduction.","Students must adhere to academic integrity; plagiarism or cheating will result in severe consequences.","Students are encouraged to work collaboratively while ensuring individual understanding of the material."],
summery:"Upon course completion, students will have the skills necessary to design and implement robust Java applications and will be well-prepared to tackle real-world software development challenges using Java."

},
{img:python,
  name:"Python Programming",
  courseDescription:"This course is designed to introduce students to the Python programming language, a versatile and widely-used high-level language known for its readability and broad applicability in areas such as web development, data analysis, artificial intelligence, scientific computing, and more. The course covers fundamental programming concepts, Python's built-in data structures, functions, object-oriented programming, and introduces essential libraries for advanced applications. Students will complete a capstone project incorporating the skills and knowledge acquired.", 
  courseObjective:["Understand Python's syntax and programming paradigms.","Apply best practices to write clean, efficient, and well-documented Python code.","Master the use of Python’s built-in data types and control structures.","Create and use functions, classes, and modules.","Work with files, exception handling, and external libraries.","Learn to use Python for real-world tasks such as web development, data analysis, and scripting.","Complete a substantial project that demonstrates the ability to solve a complex problem using Python."],
  prerequisites:["Basic understanding of programming concepts (e.g., variables, loops) is beneficial.","No prior experience with Python is required."], 
  courseOutline:[
    {module:"Introduction to Python",
     moduleContents:["Overview of Python and its applications","Setting up the Python development environment","Basic syntax, comments, and statements","Writing and running Python scripts"]},
    
    {module:"Variables and Data Types",
     moduleContents:["Variables and naming conventions","Numeric types and operations","Strings and common string operations","Boolean values and None","Type conversion and casting"]},
    
     {module:"Control Flow",
     moduleContents:["Conditional statements (`if`, `elif`, `else`)","Loops (`for`, `while`) and iterating over sequences","Comprehensions (list, set, and dictionary comprehensions)","`break`, `continue`, and `pass` statements"]},

     {module:": Data Structures",
     moduleContents:["Lists and list methods","Tuples and immutability","Dictionaries and working with key-value pairs","Sets and frozensets","Understanding sequence and collection protocols"]},

     {module:"Functions and Modules",
     moduleContents:["Defining and calling functions","Parameters, arguments, and return values","Variable scope and lifetime","Lambda functions and higher-order functions","Creating and using modules and packages"]},

     {module:"Object-Oriented Programming (OOP)",
     moduleContents:["Classes and objects","Instance methods, properties, and initializers","Inheritance and polymorphism","Encapsulation and private members","Magic methods and operator overloading"]},

     {module:"File I/O and Exception Handling",
     moduleContents:["File operations (open, read, write, and close)","Context managers with `with` statement","Handling different types of exceptions","Raising exceptions and creating custom exceptions","Using `try`, `except`, `else`, `finally` blocks"]},
     
     {module:"Working with Libraries",
     moduleContents:["Importing and using standard library modules","Virtual environments and package management with `pip`","Introduction to date and time manipulation with `datetime`","Data manipulation with `csv`, `json`, and other file formats","Math and random modules"]},
     
     {module:": Advanced Python Concepts",
     moduleContents:["Iterators and generators","Decorators and metaprogramming","Context managers and the `with` statement","Regular expressions with the `re` module","Understanding Python's memory management and garbage collection"]},
     
     {module:"Introduction to Web Development with Python",
     moduleContents:["Overview of web development and frameworks","Introduction to Flask or Django","Building a simple web application","Understanding of URL routing, HTTP methods, and templates"]},


     {module:"Data Analysis and Visualization",
     moduleContents:["Introduction to `numpy` and array operations","Data manipulation with `pandas`","Data visualization with `matplotlib` and `seaborn`","Basics of working with databases in Python"]},
     {module:"Python for Scripting and Automation",
     moduleContents:["Writing scripts to automate common tasks","Parsing command-line arguments with `argparse`","Interacting with operating system services","Scheduling tasks and automation"]}
    ],
  projectWork:[
    {title:"Project Proposal",desc:"Students identify a problem and propose a Python-based solution, including project goals, functionality, and scope."},
    {title:"Project Design",desc:"Design the software architecture and plan the development phases."},
    {title:"Project Implementation",desc:"Write Python code to develop the project, integrating various libraries and modules as needed."},
    {title:"Project Testing",desc:"Perform unit and integration testing to ensure the code is reliable and bug-free."},
    {title:"Project Deployment",desc:"Deploy the project in a suitable environment, which could vary from a web service to a standalone application."},
    {title:"Project Documentation and Presentation",desc:"Provide thorough documentation for the code and user manuals, and present the project to demonstrate its features and capabilities."},
    
    
],
projectIdeas:["A personal finance tracker with a graphical user interface.","A web scraper that collects and processes data from a website.","A REST API for a task management application.","A data analysis tool that processes and visualizes a dataset.","A simple game developed with Pygame."],
Evaluation:["Weekly Assignments: 30%","Midterm Examination: 20%","Project: 40%","Participation and Attendance: 10%"],
TextbookAndResources:["Automate the Boring Stuff with Python by Al Sweigart","Python Crash Course by Eric Matthes","Official Python documentation","Online Python community resources such as Stack Overflow, GitHub, and Reddit"],
courseDuration:"The course typically spans 15 weeks, with two 90-minute sessions each week.",
CoursePolicies:["Attendance and active participation are mandatory for all scheduled classes.","Late submissions of assignments may result in a grade reduction.","Students must adhere to academic integrity; plagiarism or cheating will result in severe consequences.","Students are encouraged to work collaboratively while ensuring individual understanding of the material."],
summery:"By the end of the course, students will have solid programming skills in Python and the ability to apply these skills to solve real-world problems effectively. They will be well-positioned to continue exploring advanced Python topics or branch into specialized fields of software development."

},




{img:net,
  name:".NET Programming",
  courseDescription:"This course is designed to provide a comprehensive introduction to the .NET framework, C# programming language, and associated tools for building a range of applications, including desktop, web, and mobile. It covers the fundamental aspects of the .NET ecosystem, including the Common Language Runtime (CLR), Base Class Library (BCL), and the use of Visual Studio. Students will learn object-oriented programming, user interface design, data access, web development using ASP.NET, and services with WCF. The course culminates in a project that integrates the lessons learned throughout the course.", 
  courseObjective:["Gain an understanding of the .NET framework and its components.","Master the C# language and its features.","Develop desktop applications with Windows Forms and WPF","Build web applications using ASP.NET and MVC.","Work with databases using ADO.NET and Entity Framework","Create and consume web services with WCF.","Understand best practices in .NET development.","Complete a real-world project using .NET technologies."],
  prerequisites:["Basic knowledge of programming concepts and principles.","Experience with another programming language is beneficial but not required."], 
  courseOutline:[
    {module:"Introduction to .NET and C#",
     moduleContents:["Overview of the .NET framework and its architecture","Understanding Common Language Runtime (CLR)","Setting up the development environment with Visual Studio","Introduction to the C# programming language","Variables, data types, operators, and control structures"]},
    
    {module:"Object-Oriented Programming in C#",
     moduleContents:["Classes and objects","Encapsulation, inheritance, and polymorphism","Interfaces and abstract classes","Properties, indexers, and events","Exception handling and debugging"]},
    
     {module:"Advanced C# Concepts",
     moduleContents:["Delegates and events","Generics and collections","Lambda expressions and LINQ","Asynchronous programming with async and await","Reflection and attributes"]},

     {module:"Desktop Application Development with Windows Forms",
     moduleContents:["Introduction to Windows Forms","Designing forms and controls","Event-driven programming","User interaction and form validation","Deployment of WinForms applications"]},

     {module:"Desktop Application Development with WPF",
     moduleContents:["Overview of Windows Presentation Foundation (WPF)","XAML syntax and data binding","Styles, templates, and user controls","Animations and multimedia","MVVM design pattern"]},

     {module:"Web Application Development with ASP.NET",
     moduleContents:["Understanding web technologies and HTTP","Introduction to ASP.NET Web Forms and Web Pages","State management and server controls","Data access with ADO.NET","ASP.NET security and membership"]},

     {module:"ASP.NET MVC and Web API",
     moduleContents:["Introduction to MVC pattern and ASP.NET MVC","Controllers, actions, and routing","Views and Razor syntax","Entity Framework and code-first development","Building and consuming RESTful services with Web API"]},
     
     {module:"Data Access with Entity Framework",
     moduleContents:["Overview of Entity Framework","Database-first and code-first approaches","LINQ to Entities and CRUD operations","Managing relationships and navigation properties","Migrations and database seeding"]},
     
     {module:"Building Services with WCF",
     moduleContents:["Introduction to Windows Communication Foundation (WCF)","Creating and hosting WCF services","Defining service contracts and data contracts","WCF clients and consuming services","Security, transactions, and exception handling in WCF"]},
     
     {module:".NET Best Practices and Design Patterns",
     moduleContents:["Understanding design patterns in .NET","Dependency injection and IoC containers","Unit testing with NUnit or MSTest","Logging and configuration","Performance optimization"]},


    ],
  projectWork:[
    {title:"Project Conceptualization",desc:"Identify a problem that can be solved with a .NET application. Define the project scope, goals, and key features."},
    {title:"Project Planning",desc:"Design the software architecture, select the appropriate .NET technologies, and plan the development phases."},
    {title:"Project Development",desc:"**: Implement the application features using C#, .NET, and related technologies."},
    {title:"Project Testing",desc:"Perform unit testing and integration testing. Address bugs and performance issues."},
    {title:"Project Deployment",desc:"Prepare for deployment, including packaging and setting up the environment."},
    {title:"Project Documentation and Presentation",desc:"Document the code, create user manuals, and present the project to demonstrate its functionality and design choices."},
    
],
projectIdeas:["An inventory management system for a small business.","An online bookstore with ASP.NET MVC and Entity Framework.","A WPF application for personal finance tracking","A RESTful service for managing to-do items and tasks.","A chat application using SignalR for real-time communication."],
Evaluation:["Assignments and Labs: 30%","Midterm Examination: 20%","Project: 40%","Participation and Attendance: 10%"],
TextbookAndResources:["Pro C# 8 with .NET Core 3 by Andrew Troelsen and Philip Japikse","Official .NET documentation","Official .NET documentation"],
courseDuration:"The course typically spans 16 weeks, with two 2-hour sessions each week",
CoursePolicies:["Attendance is essential for each session.","All assignments must be submitted on time, barring exceptional circumstances.","Academic integrity is crucial; any cheating or plagiarism will face severe penalties.","While collaboration is encouraged, all submitted work must be the student’s own."],
summery:"Upon completion of the course, students will have a strong foundation in .NET programming and will be capable of building and deploying robust applications using the .NET framework and C#."

},



{img:sql,
  name:"SQL Programming",
  courseDescription:"This course offers an in-depth look at SQL (Structured Query Language) and its use in managing and manipulating relational databases. Students will learn how to create, update, and query databases, as well as advanced topics such as stored procedures, triggers, and optimization. The course will also introduce students to database design and the principles of data normalization. The culmination of the course is a project where students will design, populate, and query a database to solve a real-world problem.", 
  courseObjective:["Understand the role and importance of databases in applications.","Learn the syntax and structure of the SQL language.","Master the creation and manipulation of database objects.","Explore advanced SQL features and best practices.","Gain practical experience by designing and querying databases.","Develop an understanding of data integrity and transactional control.","Complete a substantial project that demonstrates the ability to design and query databases."],
  prerequisites:["- Basic knowledge of computer operations","Familiarity with programming concepts is helpful but not required."], 
  courseOutline:[
    {module:"Introduction to Databases and SQL",
     moduleContents:["Overview of database systems","Types of databases: relational vs. non-relational","Introduction to SQL and its role in databases","SQL syntax and statement structure","Setting up a database environment (e.g., MySQL, PostgreSQL, SQL Server)"]},
    
    {module:"SQL Fundamentals",
     moduleContents:["Databases and tables","Data types and constraints","Basic SQL commands: `SELECT`, `INSERT`, `UPDATE`, `DELETE`","Filtering data with `WHERE`","Sorting results with `ORDER BY`"]},
    
     {module:"Working with Functions and Aggregations",
     moduleContents:["Scalar and aggregate functions","Grouping data with `GROUP BY`","Filter groups with `HAVING`","Date, string, and numeric functions","Data conversion and casting"]},

     {module:"Data Querying and Manipulation",
     moduleContents:["Combining rows from multiple tables with `JOIN`","Subqueries and correlated subqueries","Set operations: `UNION`, `INTERSECT`, `EXCEPT`","Using views for simplified querying and security","Data import/export tools and techniques"]},

     {module:"Advanced Query Techniques",
     moduleContents:["Window functions and analytical queries","Complex queries with Common Table Expressions (CTEs)","Recursive queries","Pivoting and unpivoting data","Using indexing for performance improvement"]},

     {module:"Database Design and Normalization",
     moduleContents:["Database design principles","Understanding normalization (1NF, 2NF, 3NF, BCNF)","Identifying and creating primary and foreign keys","Entity-Relationship (ER) modeling","Designing and implementing a normalized database schema"]},

     {module:"Transaction Management and Data Integrity",
     moduleContents:["ACID properties of transactions","Using transactions to manage data integrity","`COMMIT` and `ROLLBACK` operations","Locking mechanisms and concurrency control","Understanding isolation levels"]},
     
     {module:"Stored Procedures and Triggers",
     moduleContents:["Creating and using stored procedures","Variables, parameters, and control-of-flow statements","Error handling within stored procedures","Designing and utilizing triggers for data validation and automation","Best practices and security considerations"]},
     
     {module:": Advanced Database Features",
     moduleContents:["Indexes and performance tuning","Materialized views and caching","Full-text search and text processing","Working with XML and JSON data in SQL","Exploring NoSQL features in relational databases"]},
     
     {module:"Database Security and User Management",
     moduleContents:["Database security fundamentals","Creating and managing user accounts","Permissions and roles","Auditing and compliance considerations","Securing data with encryption"]},


    
    ],
  projectWork:[
    {title:"Project Proposal",desc:"Students will propose a project that includes a problem statement, outlining the type of data and the kind of database application they plan to develop."},
    {title:"Database Design",desc:"Create a conceptual design using ER modeling, then translate it into a physical schema."},
    {title:"Data Population",desc:"Develop scripts to populate the database with sample data."},
    {title:"Query Development",desc:"Write SQL queries to extract meaningful insights from the database, including reports, summaries, and analytical results."},
    {title:"Optimization",desc:"Optimize the database by adding indexes, tuning queries, and considering best practices in design."},
    {title:"Documentation and Presentation ",desc:"Document the database schema, the queries used, and any stored procedures or triggers created. Present the database project, highlighting the design choices, challenges faced, and insights obtained from the data."},
    
],
projectIdeas:["A customer relationship management (CRM) database","An e-commerce platform's back-end database.","A student information system for a school.","A database for managing a public library's inventory and user accounts."," An inventory and sales data analysis system for a small business."],
Evaluation:["Weekly Quizzes and Exercises: 25%","Midterm Exam: 20%","Project: 45%","Participation and Attendance: 10%"],
TextbookAndResources:["SQL for Data Scientists: A Beginner's Guide for Building Datasets for Analysis by Renee M. P. Teate","Database software documentation (e.g., MySQL Documentation, PostgreSQL Manuals)","Online SQL tutorials and interactive platforms (e.g., SQLZoo, Codecademy)"],
courseDuration:"The course is designed for a 12-week period, with two 1.5-hour sessions per week.",
CoursePolicies:["Regular attendance is important to keep up with the material.","Assignments must be completed by the deadlines","Academic integrity is expected at all times. Plagiarism or cheating will result in disciplinary action.","Active participation is encouraged, including in-class discussions and online forums."],
summery:"By the end of the course, students will be proficient in SQL and will understand how to design and interact with databases to support the data needs of applications. They will be able to tackle complex data-related problems and will be well-prepared for roles that require strong database and SQL skills."

},






{img:fullstack,
  name:"Frontend Development  Programming",
  courseDescription:"This course provides an in-depth exploration of the foundational technologies used for frontend web development: HTML, CSS, and JavaScript. Students will learn how to create interactive and responsive web pages from scratch. They will begin by understanding the structure of web content with HTML, then move on to styling with CSS, and finally, learn to add interactivity with JavaScript. The course culminates in a capstone project where students will apply their knowledge to build a fully functional website.", 
  courseObjective:["Gain a solid understanding of HTML, CSS, and JavaScript.","Develop skills to create responsive and accessible web pages.","Learn how to structure, design, and interactivity to a website.","Familiarize oneself with best practices and web standards.","Complete a comprehensive project incorporating all the learned skills."],
  prerequisites:["Basic computer skills and familiarity with internet browsing.","No prior experience in web development is required."  ], 
  courseOutline:[
    {module:"Introduction to Web Development",
     moduleContents:["Overview of web development","Understanding the internet and how web pages work","Introduction to web development tools (text editors, browsers, developer tools)"  ]},
    
    {module:"Getting Started with HTML",
     moduleContents:["HTML structure and syntax","Basic elements (headings, paragraphs, lists, links, images)","Semantic HTML5 elements (header, nav, article, section, footer)","Forms and input types","Structuring page content effectively"]},
    
     {module:"Styling with CSS",
     moduleContents:["CSS syntax and selectors","The box model (margin, border, padding, content)","The box model (margin, border, padding, content)","Implementing CSS layout techniques (flexbox, grid)","Responsive design with media queries","CSS3 features (transitions, transformations, animations)"]},

     {module:"Advanced CSS Techniques",
     moduleContents:["Advanced layout and positioning","Pseudo-classes and pseudo-elements","Preprocessors like SASS or LESS","CSS frameworks (Bootstrap, Tailwind CSS)","Best practices for maintainable CSS"]},

     {module:"Introduction to JavaScript",
     moduleContents:["JavaScript syntax and basic programming concepts (variables, data types, operators)","Control structures (conditionals, loops)","Functions and scope","DOM manipulation and event handling","Form validation"]},

     {module:"Intermediate JavaScript",
     moduleContents:["Understanding Arrays and Objects","JSON and data structures","Advanced DOM manipulation","Asynchronous JavaScript (callbacks, promises, async/await)","Fetch API and AJAX for server communication"]},

     {module:"Advanced JavaScript and Frontend Development",
     moduleContents:["Introduction to ES6 features (let/const, arrow functions, template literals, etc.)","Modules and namespacing","Error handling and debugging","JavaScript best practices and patterns","Introduction to web accessibility"]},
     
     {module:"Working with APIs",
     moduleContents:["Understanding APIs and endpoints","Making HTTP requests and handling responses","Integrating third-party APIs","Creating a simple backend using services like Firebase or Node.js" ]},
     
     {module:"Tools and Workflows",
     moduleContents:["Version control with Git and GitHub","Introduction to build tools (Webpack, Gulp)","Code quality tools (ESLint, Prettier)","Basics of package management (npm, yarn)" ]},
     
     {module:"Introduction to Frameworks and Libraries",
     moduleContents:["Overview of JavaScript frameworks and libraries (React, Angular, Vue)","Understanding the importance of components and state management","Single Page Applications (SPA) and client-side routing","Basic introduction to the Virtual DOM" ]},

 
    ],
  projectWork:[
    {title:"Project Planning",desc:"**: Identify a project idea, define the scope, and plan the layout and functionality."},
    {title:"Design Phase",desc:"Create wireframes and decide on the color scheme, typography, and images/icons to be used."},
    {title:"Development Phase",desc:"Code the HTML structure, style using CSS, and add interactivity with JavaScript."},
    {title:"Testing and Debugging",desc:"Ensure the website is responsive, accessible, and free of bugs"},
    {title:"Deployment",desc:"Deploy the website to a hosting service such as GitHub Pages or Netlify."},
    {title:"Documentation and Presentation",desc:"Document the design and development process and prepare a presentation of the final project."},
    
],
projectIdeas:["A portfolio website showcasing personal or professional work","A restaurant website with menus, photos, and the ability to make reservations.","An interactive quiz or game","A simple e-commerce storefront with product listings and a shopping cart.","A content management system (CMS) for blogging or articles."],
Evaluation:["Weekly Assignments: 40%","Midterm Examination: 20%","Capstone Project: 30%","Participation and Attendance: 10%" ],
TextbookAndResources:["HTML and CSS: Design and Build Websites by Jon Duckett","JavaScript and JQuery: Interactive Front-End Web Development  by Jon Duckett","Online documentation (MDN Web Docs, W3Schools)","Web development communities and forums (Stack Overflow, freeCodeCamp)" ],
courseDuration:"The course is typically conducted over a 12-week period, with two 2-hour sessions each week",
CoursePolicies:["Regular attendance and active participation are required.","Assignments must be submitted on time; extensions may be granted in exceptional cases","Plagiarism is strictly prohibited and will result in severe consequences.","Students are encouraged to help each other but must submit their own work"  ],
summery:"By the end of the course, students will be equipped with the skills to build responsive and interactive websites, and will be prepared to further their studies in more specialized areas of web development."

},




{img:tally,
  name:"Tally with GST ",
  courseDescription:"This course aims to train students in using Tally, which is a widely-used accounting software, with a special focus on the Goods and Services Tax (GST) in India. It covers Tally’s accounting features, inventory management, tax handling, and the intricacies of GST in business transactions. Students will learn to manage accounts, perform various accounting operations, and comply with the GST law using Tally software. The course concludes with a hands-on project where students will simulate a business’s financial activities and prepare its accounts, including GST returns.", 
  courseObjective:["Learn the basics of Tally and its application in accounting.","Learn the basics of Tally and its application in accounting.","Learn the basics of Tally and its application in accounting.","Develop skills to maintain accounts and manage inventory in Tally.","Complete a practical project involving real-world business accounting with GST compliance."],
  prerequisites:["Basic understanding of accounting principles.","Familiarity with the Indian Taxation System and GST is helpful but not mandatory." ], 
  courseOutline:[
    {module:"Introduction to Tally",
     moduleContents:["Overview of Tally software","Installation and configuration of Tally","Introduction to Tally's user interface","Creating a company and setting up company features" ]},
    
    {module:"Basics of Accounting",
     moduleContents:["Fundamentals of accounting","Understanding ledgers and groups","Creating accounting vouchers","Recording sales, purchases, receipts, payments, and journal entries" ]},
    
     {module:"Inventory Management in Tally",
     moduleContents:["Setting up inventory in Tally","Inventory groups, categories, stock items, and units of measure","Recording inventory vouchers","Generating inventory reports" ]},

     {module:"Advanced Accounting in Tally",
     moduleContents:["Multilingual capabilities","Interest calculations and cost center management","Budget and controls","Bank reconciliation in Tally" ]},

     {module:"Introduction to GST",
     moduleContents:["Understanding GST and its framework","Types of GST: CGST, SGST, IGST","GST rates and HSN/SAC codes","GST compliance: invoicing, returns, and refunds" ]},

     {module:"GST in Tally",
     moduleContents:["Enabling and configuring GST in Tally","Creating GST ledgers and classifying goods and services","Recording GST transactions","Adjusting Input Credit against GST liabilities"  ]},

     {module:"GST Returns and E-Filing",
     moduleContents:["Overview of various GST returns (GSTR-1, GSTR-2, GSTR-3B, etc.)","Generating GST returns from Tally","Reconciling data for GST returns","E-filing of GST returns through Tally and the GST portal" ]},
     
     {module:"Advanced GST Features in Tally",
     moduleContents:["Advanced GST set-offs and adjustments","Handling reverse charge mechanism (RCM)","GST for composite dealers","GST annual return and audit" ]},
     
     {module:"Payroll Management in Tally",
     moduleContents:["Managing payroll categories","Processing payroll in Tally","Payroll compliance with Tally (PF, ESI, TDS, etc.)","Generating payroll reports"]},
     
     {module:"Data Management and Security in Tally",
     moduleContents:["Data backup and restore","Tally Vault and User Management","Splitting company data and exporting/importing data","Audit features in Tally" ]},


     {module:"Tally Customization and Integration",
     moduleContents:["Basic Tally customization","Introduction to TDL (Tally Definition Language)","Tally integration with other software and services" ]}
    ],
  projectWork:[
    {title:"Project Planning",desc:"Create a business scenario for the project, including its nature, size, and transaction details."},
    {title:"Company Setup",desc:"**: Use Tally to create the company, define its features, and configure GST settings."},
    {title:"Accounting Operations",desc:"Record typical business transactions over a period, including sales, purchases, and inventory movements."},
    {title:"GST Compliance",desc:"Apply GST rates, generate invoices, and calculate GST liabilities. Record GST-related transactions and prepare GST returns."},
    {title:"Financial Statements and Analysis",desc:"Prepare financial statements including the balance sheet, profit and loss account, and cash flow statement. Analyze the financial health of the business."},
    {title:"Project Presentation",desc:"Present the completed project, showcasing how Tally was used to manage the business’s accounting needs and GST compliance, discussing any challenges faced and how they were resolved."},
    
],
projectIdeas:[""],
Evaluation:["Weekly Assignments: 40%","Midterm Examination: 20%","Capstone Project: 30%","Participation and Attendance: 10%" ],
TextbookAndResources:["Tally official documentation","Comprehensive Guide to GST in Tally  by a recognized accounting expert","GST official documentation and resources","Tally community forums and support websites"],
courseDuration:"The course is typically conducted over a 10-week period, with two 2-hour sessions each week.",
CoursePolicies:["Regular attendance and active participation are mandatory","Assignments must be submitted by the due dates, and late submissions will be penalized.","Students are expected to maintain academic integrity and honesty.","Collaboration among students is encouraged, but copying work is strictly forbidden."],
summery:"By the end of the course, students will be able to manage a company's accounts and comply with GST regulations using Tally software effectively. They will be prepared for roles in accounting, finance, and tax preparation that require Tally expertise."

},




  
  // {img:cpp,name:"jgv++"},
  // {img:java,name:"JAVA"},
  // {img:python,name:"PYTHON"},
  // {img:sql,name:"SQL"},
  // {img:php,name:"PHP"},
  // {img:net,name:".NET"}

];



function Courses() {
const navigate=useNavigate();
const { setstate } = useCourseContext()


const handleViewDetails = (ele) => {
  // Use navigate to redirect to the CourseDetail page and pass the data
setstate(ele)
  navigate('/courseDetails');
};
  return (
    <div id="courses">
      <div className="m-auto" style={{width:'80%'}}>
<div className="mt-5"><h5 class="intro">Introduction</h5></div>
<div><h2>Welcome to Diploma Courses</h2></div></div>
 <div className="d-flex justify-content-center flex-wrap" style={{width:'80%', margin:'auto'}}>
  {store2.map((ele,i)=>(
    <Card className="crds" style={{ width: '15rem', margin :'20px',borderRadius:'10px',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px'}}>
    <Card.Img class="cardImg" variant="top" src={ele.img} />
    <Card.Body>
      <Card.Title>{ele.name}</Card.Title>
      <Card.Text>
        {ele.courseObjective[1]}
       
      </Card.Text>
      <Button variant="primary" className="" onClick={()=>handleViewDetails(ele)}>Know More</Button>
     
    </Card.Body>
  </Card>
  ))}

</div>

    </div>


  );
}

export { Courses };